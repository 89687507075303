import React, { FC, useEffect, useRef, useState } from 'react';
import { config } from '../config';
import {
    DestinationName,
    EventActionType,
    EventSource,
    EventTarget,
    SetAddressPresetEventPayload,
    UpdatePositionEventPayload,
} from '../core/types';
import { BRZNativeAPI } from './BRZNativeAPI';

export const DevTest: FC = () => {
    const navigation = useRef<HTMLIFrameElement>(null);
    const widget = useRef<HTMLIFrameElement>(null);
    const [position, setPosition] = useState(config.initialPosition);
    const [uiComparison, toggleUIComparison] = useState(false);
    const appUrl = config.isProduction
        ? 'http://cginav.inteum.pl'
        : 'http://localhost:3000';
    useEffect(() => {
        if (navigation.current && widget.current) {
            [navigation, widget].forEach((frame: any) => {
                if (frame.current) {
                    const wnd = frame.current as any;
                    wnd.contentWindow.BRZNativeAPI = new BRZNativeAPI(
                        navigation as any,
                        widget as any,
                    );
                }
            });
        }
    }, [navigation, widget]);
    const sendEvent = (data: object) => {
        (navigation.current!.contentWindow as any).sendEvent(
            JSON.stringify(data),
        );
    };
    const sendEventToWidget = (data: object) => {
        (widget.current!.contentWindow as any).sendEvent(JSON.stringify(data));
    };
    const sendToggleBackgroundMode = () => {
        sendEvent({
            type: EventActionType.ToggleBackgroundMode,
            source: EventSource.Incari,
            target: EventTarget.Navigation,
        });
    };
    const sendSimulatedPositionUpdate = () => {
        const { lat, lng } = position;
        setPosition({
            lat: lat + 0.0001,
            lng: lng + 0.0001,
        });
        sendEvent({
            type: EventActionType.UpdatePosition,
            source: EventSource.Incari,
            target: EventTarget.Navigation,
            payload: {
                lat,
                lng,
                bearing: 0,
            } as UpdatePositionEventPayload,
        });
    };
    const sendUpdateAddressPreset = (preset: DestinationName) => () => {
        sendEvent({
            type: EventActionType.SetAddressPreset,
            source: EventSource.Incari,
            target: EventTarget.Navigation,
            payload: {
                preset,
                address1: 'Straßbergerstraße 6',
                address2: '80809 München',
                coordinates: {
                    lat: 48.18317725134149,
                    lng: 11.55256988838938,
                },
            } as SetAddressPresetEventPayload,
        });
    };
    const sendUIComparisonEvent = (state: boolean) => {
        sendEventToWidget({
            type: EventActionType.ToggleUIComparison,
            source: EventSource.Incari,
            target: EventTarget.Widget,
            payload: state,
        });
    };

    const toggleCompareUI = () => {
        toggleUIComparison(!uiComparison);
        sendUIComparisonEvent(!uiComparison);
    };
    return (
        <div className="dev-test">
            <main className="dev-test__main">
                <iframe
                    id="navigation"
                    className="dev-test__navigation"
                    ref={navigation}
                    src={`${appUrl}/app`}
                    title="navigation"
                />
                <iframe
                    id="widget"
                    className="dev-test__widget"
                    ref={widget}
                    src={`${appUrl}/widget`}
                    title="widget"
                />
            </main>
            <div className="dev-test__actions">
                <button
                    className="dev-test__action"
                    onClick={sendToggleBackgroundMode}
                >
                    Toggle background mode
                </button>
                <button
                    className="dev-test__action"
                    onClick={sendSimulatedPositionUpdate}
                >
                    Set location
                </button>
                <button
                    className="dev-test__action"
                    onClick={sendUpdateAddressPreset(DestinationName.Home)}
                >
                    Change Home address
                </button>
                <button
                    className="dev-test__action"
                    onClick={sendUpdateAddressPreset(DestinationName.Work)}
                >
                    Change Work address
                </button>
                <button className="dev-test__action" onClick={toggleCompareUI}>
                    {`UI comparison ${uiComparison ? 'ON' : 'OFF'}`}
                </button>
            </div>
        </div>
    );
};
