import { createSelector } from 'reselect';
import { AppState } from '../../reducer';
import { DestinationName } from '../types';

const searchResults = (state: AppState) => state.search.results;
const searchQuery = (state: AppState) => state.search.query;
const destinations = (state: AppState) => state.search.destinations;
const currentDestinationName = (state: AppState) =>
    state.search.currentDestination;

const destinationByName = (state: AppState) => (name: DestinationName) =>
    state.search.destinations[name];

const currentDestination = createSelector(
    destinations,
    currentDestinationName,
    (destinations, currentName) => destinations[currentName as string] || null,
);

export const searchSelectors = {
    destinations,
    searchResults,
    searchQuery,
    destinationByName,
    currentDestination,
};
