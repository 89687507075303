import { combineReducers } from 'redux';
import {
    navigationReducer as navigation,
    searchReducer as search,
    mapReducer as map,
    widgetReducer as widget,
} from './core/reducers';

const rootReducer = combineReducers({
    map,
    search,
    navigation,
    widget,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
