import cx from 'classnames';
import React, { FC, ReactNode } from 'react';

export const Panel: FC<PanelProps> = ({
    className,
    children,
    isOpen,
    fullSize,
}) => {
    const classes = cx(
        'panel',
        { 'panel--open': isOpen, 'panel--full-size': fullSize },
        className,
    );
    return <div className={classes}>{children}</div>;
};

interface PanelProps {
    children: ReactNode;
    className?: string;
    isOpen: boolean;
    fullSize: boolean;
}
