/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-ignore
import { EaseToOptions, MapMouseEvent } from '!mapbox-gl';
import React, {
    FC,
    ReactNode,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useMap, useNavigation } from '../../hooks';
import { MapContext } from './MapProvider';

const SIMULATION_TRANSITION = 1000;
const INTERACTIONS_TRANSITION = 500;

export const Map: FC<MapProps> = ({ onClick, children }) => {
    const [mapConfigured, setMapConfigured] = useState(false);
    const { map, mapContainer } = useContext(MapContext);
    const {
        bearing,
        pitch,
        zoom,
        offset,
        lat,
        lng,
        updateHash,
        setFreeMode,
        updateMapViewport,
    } = useMap();
    const { simulationRunning, showRouteOverview } = useNavigation();
    // One-time initialization
    useLayoutEffect(() => {
        if (map && !mapConfigured) {
            map.on('click', function (event: MapMouseEvent) {
                if (onClick) {
                    onClick(event);
                }
            });
            map.on('moveend', function () {
                const { lat, lng } = map.getCenter();
                const bearing = map.getBearing();
                const pitch = map.getPitch();
                const zoom = map.getZoom();
                updateMapViewport({
                    lat,
                    lng,
                    bearing,
                    pitch,
                    zoom,
                });
            });
            map.on('dragstart', function () {
                setFreeMode(true);
            });
            setMapConfigured(true);
        }
        if (document) {
            const mapBoxLogo = document.getElementsByClassName(
                'mapboxgl-ctrl-logo',
            )[0];
            if (mapBoxLogo) {
                mapBoxLogo.removeAttribute('href');
                mapBoxLogo.removeAttribute('target');
            }
        }
    }, [map]);
    // Handle external map viewport updates
    useEffect(() => {
        if (!map) {
            return;
        }
        const params: EaseToOptions = {
            pitch,
            bearing,
            zoom,
            center: [lng, lat],
            offset: offset as any,
            duration:
                simulationRunning && !showRouteOverview
                    ? SIMULATION_TRANSITION
                    : INTERACTIONS_TRANSITION,
            ...(simulationRunning ? { easing: (t: any) => t } : {}),
        };
        (map as mapboxgl.Map).easeTo(params);
    }, [map, bearing, pitch, zoom, lat, lng, updateHash]);
    return (
        <div className="map" ref={mapContainer}>
            {children}
        </div>
    );
};

export interface MapProps {
    onClick: (e: MapMouseEvent) => void;
    children?: ReactNode;
}
