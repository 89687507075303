import React, { FC } from 'react';
import { Guidance } from '../../drive';
import { useWidget } from '../../hooks';
import { WidgetLayout } from './WidgetLayout';
import { WidgetMenu } from './WidgetMenu';

export const Widget: FC<WidgetProps> = ({ className }) => {
    const { guidance, driveMode, devUIComparison } = useWidget();
    const isDevUIComparisonVisible = devUIComparison && !driveMode;
    return (
        <WidgetLayout
            className={className}
            uiComparison={isDevUIComparisonVisible}
        >
            {driveMode && guidance ? (
                <Guidance data={guidance} />
            ) : (
                <WidgetMenu />
            )}
        </WidgetLayout>
    );
};

interface WidgetProps {
    className?: string;
}
