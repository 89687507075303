import { FC, ReactElement, ReactNode } from 'react';

export function callIf(
    element: any,
    condition: boolean,
    fn: (arg: any) => any,
) {
    return condition ? fn(element) : element;
}

export const WrapWhen: FC<WrapWhenProps> = ({
    condition,
    wrapper,
    children,
}) => {
    return (condition ? wrapper(children) : children) as ReactElement;
};

interface WrapWhenProps {
    children: ReactNode;
    condition: boolean;
    wrapper: (arg: ReactNode) => ReactNode;
}
