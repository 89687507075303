/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-ignore
import { GeoJSONSource, LineLayout, LngLatBounds } from '!mapbox-gl';
import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { MapContext } from '../../components';
import { MapLayerProps } from '../../core/types';

const ROUTE_COLOR = '#7096c2'; // $color-blue2
const ROUTE_WIDTH = 5;

export const MapRoute: FC<MapRouteProps> = ({
    geometry,
    visible,
    overviewMode,
}) => {
    const { map } = useContext(MapContext);
    const [layerAdded, setLayerAdded] = useState(false);
    useLayoutEffect(() => {
        if (map && !layerAdded && visible) {
            map.addSource('route', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
            });
            map.addLayer(
                {
                    id: 'route-layer',
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    } as LineLayout,
                    paint: {
                        'line-color': ROUTE_COLOR,
                        'line-width': ROUTE_WIDTH,
                    },
                },
                'layer-marker-position',
            );
            setLayerAdded(true);
        }
    }, [geometry, visible]);
    useEffect(() => {
        if (map && layerAdded && !visible) {
            map.removeLayer('route-layer');
            map.removeSource('route');
            setLayerAdded(false);
        }
    }, [visible]);
    useEffect(() => {
        if (layerAdded && map && geometry) {
            const { coordinates } = geometry;
            (map.getSource('route') as GeoJSONSource).setData({
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates,
                },
            });
            const bounds = coordinates.reduce(function (
                bounds: LngLatBounds,
                coord: any,
            ) {
                return bounds.extend(coord);
            },
            new LngLatBounds(coordinates[0], coordinates[0]));
            map.fitBounds(bounds, {
                padding: {
                    top: 150,
                    bottom: 150,
                    left: 400,
                    right: 400,
                },
            });
        }
    }, [overviewMode, layerAdded, geometry]);
    return null;
};

interface MapRouteProps extends MapLayerProps {
    overviewMode: boolean;
}
