import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RouteSummary } from '../routes';
import { DrivingMenu, TurnByTurn } from './components';

export const Drive: FC<DriveProps> = () => {
    const { path } = useRouteMatch();
    const getPath = (url: string) => `${path}/${url}`;
    return (
        <div className="drive">
            <Switch>
                <Route exact path={path}>
                    <TurnByTurn />
                </Route>
                <Route path={getPath('menu')}>
                    <DrivingMenu />
                </Route>
                <Route path={getPath('route')}>
                    <RouteSummary />
                </Route>
            </Switch>
        </div>
    );
};

interface DriveProps {}
