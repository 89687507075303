import React, { FC } from 'react';
import markerDestination from '../../assets/images/marker-destination.png';
import { MapMarker } from '../../components';
import { MapLayerProps } from '../../core/types';

const getCoordinates = (geometry: any) => {
    if (!geometry || !geometry.coordinates) {
        return { lng: 0, lat: 0 };
    }
    const { coordinates } = geometry;
    const point = coordinates[coordinates.length - 1];
    return {
        lng: point[0],
        lat: point[1],
    };
};

export const DestinationMarker: FC<MapLayerProps> = ({ geometry, visible }) => {
    return (
        <MapMarker
            id="marker-destination"
            image={markerDestination}
            alignToMap={false}
            iconAnchor="bottom"
            visible={visible && geometry && geometry.coordinates}
            {...getCoordinates(geometry)}
        />
    );
};
