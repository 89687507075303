import { Coordinates, Destination, DestinationName } from './core/types';

const getCoords = (coords: string): Coordinates => {
    const [lat, lng] = coords.split(',');
    return {
        lat: Number(lat),
        lng: Number(lng),
    };
};

export interface AppConfig {
    isProduction: boolean;
    mapboxToken: string;
    mapStyle: string;
    home: Destination;
    work: Destination;
    initialPosition: Coordinates;
}

export const config: AppConfig = {
    isProduction: process.env.NODE_ENV === 'production',
    mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
    mapStyle: process.env.REACT_APP_MAPBOX_MAP_STYLE as string,
    initialPosition: getCoords(
        process.env.REACT_APP_INITIAL_POSITION as string,
    ),
    [DestinationName.Home]: {
        address1: process.env.REACT_APP_HOME_ADDRESS1 as string,
        address2: process.env.REACT_APP_HOME_ADDRESS2 as string,
        coordinates: getCoords(process.env.REACT_APP_HOME_POSITION as string),
    },
    [DestinationName.Work]: {
        address1: process.env.REACT_APP_WORK_ADDRESS1 as string,
        address2: process.env.REACT_APP_WORK_ADDRESS2 as string,
        coordinates: getCoords(process.env.REACT_APP_WORK_POSITION as string),
    },
};
