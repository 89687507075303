import { CameraOptions, Coordinates } from '../types';
export interface PositionMarkerOptions extends Coordinates {
    rotation?: number;
}

export enum MapActionType {
    SetZoom = 'MAP/SET_ZOOM',
    SetCurrentPosition = 'MAP/SET_CURRENT_POSITION',
    UpdateViewport = 'MAP/UPDATE_VIEWPORT',
    SetCamera = 'MAP/SET_CAMERA',
    ResetCamera = 'MAP/RESET_CAMERA',
    ToggleBuildings = 'MAP/TOGGLE_3D_BUILDINGS',
    SetFreeMode = 'MAP/SET_FREE_MODE',
    ToggleBackgroundMode = 'MAP/TOGGLE_BACKGROUND_MODE',
}

export interface SetZoomAction {
    type: MapActionType.SetZoom;
    payload: number;
}

export interface SetCameraAction {
    type: MapActionType.SetCamera;
    payload: CameraOptions;
}

export interface ResetCameraAction {
    type: MapActionType.ResetCamera;
}

export interface SetCurrentPositionAction {
    type: MapActionType.SetCurrentPosition;
    payload: PositionMarkerOptions;
}

export interface UpdateViewportAction {
    type: MapActionType.UpdateViewport;
    payload: CameraOptions;
}

export interface ToggleBuildingsAction {
    type: MapActionType.ToggleBuildings;
}

export interface ToggleBackgroundModeAction {
    type: MapActionType.ToggleBackgroundMode;
}

export interface SetFreeModeAction {
    type: MapActionType.SetFreeMode;
    payload: boolean;
}

export type MapAction =
    | SetZoomAction
    | SetCurrentPositionAction
    | UpdateViewportAction
    | SetCameraAction
    | ResetCameraAction
    | ToggleBuildingsAction
    | SetFreeModeAction
    | ToggleBackgroundModeAction;
