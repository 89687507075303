import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { ButtonBase, ButtonBaseProps, getButtonClasses } from './ButtonBase';

export const LinkButton: FC<LinkButtonProps> = ({
    to,
    icon,
    iconSize,
    iconColor,
    size,
    label,
    disabled,
    className,
    onClick,
    children,
    variant,
}) => {
    const handleClick = (event: MouseEvent) => {
        if (disabled) {
            event.preventDefault();
            return false;
        }
        if (onClick) {
            onClick(event);
        }
        return;
    };
    return (
        <Link
            to={to}
            className={getButtonClasses(size, disabled, false, className)}
            onClick={handleClick}
        >
            <ButtonBase
                icon={icon}
                iconSize={iconSize}
                iconColor={iconColor}
                label={label}
                variant={variant}
            >
                {children}
            </ButtonBase>
        </Link>
    );
};

export interface LinkButtonProps extends ButtonBaseProps {
    to: string;
}
