/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { BackButton, Input, Keyboard } from '../components';
import { useKeyboardTextInput } from '../hooks';
import { useSearch } from '../hooks/useSearch';
import { SearchResults } from './components';

const SEARCH_DELAY = 500;

export const Search: FC = () => {
    const {
        search,
        results,
        setCustomDestination,
        query: q,
        clearResults,
    } = useSearch();
    const [
        ref,
        query,
        onInputChange,
        onKeyboardPress,
        onInputClear,
    ] = useKeyboardTextInput(q);
    const [debouncedQuery] = useDebounce(query, SEARCH_DELAY);
    useEffect(() => {
        search(debouncedQuery);
    }, [debouncedQuery]);
    const handleClearSearch = () => {
        clearResults();
        onInputClear();
    };
    const history = useHistory();
    const handleSelectDestination = (index: number) => {
        if (results[index]) {
            setCustomDestination(results[index]);
            history.push('/app/route/custom');
        }
    };
    return (
        <div className="search">
            <div className="search__form">
                <BackButton label="Back" />
                <Input
                    ref={ref}
                    className="search__query"
                    placeholder="Type address..."
                    onChange={onInputChange}
                    value={query}
                    onClear={handleClearSearch}
                    clearable={query !== ''}
                />
                <div className="search__results">
                    <SearchResults
                        results={results}
                        onSelectResult={handleSelectDestination}
                    />
                </div>
            </div>
            <div className="search__keyboard">
                <Keyboard
                    onKeyPress={onKeyboardPress}
                    onEnter={() => handleSelectDestination(0)}
                />
            </div>
        </div>
    );
};
