import { DestinationDetails, DestinationName, RouteGuidance } from '../types';

export enum WidgetActionType {
    SetDestinationDetails = 'WIDGET/SET_DESTINATION_DETAILS',
    UpdateGuidance = 'WIDGET/UPDATE_ROUTE_GUIDANCE',
    ToggleDriveMode = 'WIDGET/TOGGLE_DRIVE_MODE',
    ToggleUIComparison = 'WIDGET/DEV_UI_COMPARISON',
}

export interface SetDestinationDetailsAction {
    type: WidgetActionType.SetDestinationDetails;
    payload: {
        name: DestinationName;
        details: DestinationDetails;
    };
}

export interface UpdateWidgetRouteGuidanceAction {
    type: WidgetActionType.UpdateGuidance;
    payload: RouteGuidance;
}

export interface ToggleDriveModeAction {
    type: WidgetActionType.ToggleDriveMode;
    payload: boolean;
}

export interface ToggleUIComparison {
    type: WidgetActionType.ToggleUIComparison;
    payload: boolean;
}

export type WidgetAction =
    | SetDestinationDetailsAction
    | UpdateWidgetRouteGuidanceAction
    | ToggleDriveModeAction
    | ToggleUIComparison;
