import React, { ReactElement } from 'react';
import { Icon, IconSize, IconType } from '../icon';

export enum SpecialKeyType {
    Backspace = 'BACKSPACE',
    Enter = 'ENTER',
    Space = 'SPACE',
    ArrowLeft = 'ARROW-LEFT',
    ArrowRight = 'ARROW-RIGHT',
    LayoutSwitch = 'LAYOUT-SWITCH',
}

export interface KeyboardSpecialKey {
    type: SpecialKeyType;
    render: string | ReactElement;
}

export interface KeyboardLayoutRow {
    specialLeft?: KeyboardSpecialKey[];
    characters?: string[];
    specialRight?: KeyboardSpecialKey[];
}

export const keyboardLayout: KeyboardLayoutRow[] = [
    {
        characters: ['qwertyuiop', '1234567890'],
        specialRight: [
            {
                type: SpecialKeyType.Backspace,
                render: (
                    <Icon icon={IconType.Backspace} size={IconSize.XLarge} />
                ),
            },
        ],
    },
    {
        characters: ['asdfghjkl', '/;:,.\'"()'],
        specialRight: [{ type: SpecialKeyType.Enter, render: 'SEARCH' }],
    },
    {
        characters: ['zxcvbnm-,.', '%-+=@#€&*'],
    },
    {
        specialLeft: [
            { type: SpecialKeyType.LayoutSwitch, render: '?123' },
            { type: SpecialKeyType.Space, render: '' },
        ],
        specialRight: [
            {
                type: SpecialKeyType.ArrowLeft,
                render: <Icon icon={IconType.Prev} size={IconSize.XLarge} />,
            },
            {
                type: SpecialKeyType.ArrowRight,
                render: <Icon icon={IconType.Next} size={IconSize.XLarge} />,
            },
        ],
    },
];
