import { Route, RouteGuidance, RouteProgress } from '../types';

export enum NavigationActionType {
    SetRoutes = 'NAVIGATION/SET_ROUTES',
    ToggleRoute = 'NAVIGATION/TOGGLE_ROUTE',
    ClearRoute = 'NAVIGATION/CLEAR_ROUTE',
    UpdateRouteProgress = 'NAVIGATION/UPDATE_ROUTE_PROGRESS',
    UpdateGuidance = 'NAVIGATION/UPDATE_ROUTE_GUIDANCE',
    ToggleError = 'NAVIGATION/TOGGLE_ERROR',
    StartSimulation = 'NAVIGATION/START_SIMULATION',
    StopSimulation = 'NAVIGATION/STOP_SIMULATION',
    SetRouteOverview = 'NAVIGATION/SET_ROUTE_OVERVIEW',
}
export interface SetRoutesAction {
    type: NavigationActionType.SetRoutes;
    payload: Route[];
}
export interface ToggleCurrentRouteAction {
    type: NavigationActionType.ToggleRoute;
    payload: number;
}

export interface ClearRouteAction {
    type: NavigationActionType.ClearRoute;
}

export interface ToggleNavigationErrorAction {
    type: NavigationActionType.ToggleError;
    payload: boolean;
}

export interface SetRouteOverviewAction {
    type: NavigationActionType.SetRouteOverview;
    payload: boolean;
}

export interface UpdateRouteProgressAction {
    type: NavigationActionType.UpdateRouteProgress;
    payload: RouteProgress;
}

export interface UpdateRouteGuidanceAction {
    type: NavigationActionType.UpdateGuidance;
    payload: RouteGuidance;
}

export interface StartSimulationAction {
    type: NavigationActionType.StartSimulation;
}

export interface StopSimulationAction {
    type: NavigationActionType.StopSimulation;
}

export type NavigationAction =
    | SetRoutesAction
    | ToggleCurrentRouteAction
    | ClearRouteAction
    | UpdateRouteProgressAction
    | ToggleNavigationErrorAction
    | UpdateRouteGuidanceAction
    | StartSimulationAction
    | StopSimulationAction
    | SetRouteOverviewAction;
