import React, { FC, ReactNode } from 'react';

const sides: string[] = ['top', 'bottom', 'left', 'right'];

export const MapMask: FC<MapMaskProps> = ({ children }) => {
    return (
        <div className="map-mask">
            <div className="map-mask__content">{children}</div>
            {sides.map((side) => (
                <div key={`map-mask-${side}`} className={`map-mask__${side}`} />
            ))}
        </div>
    );
};

interface MapMaskProps {
    children: ReactNode;
}
