import cx from 'classnames';
import React, { FC } from 'react';
import { Icon, IconColor, IconSize, IconType } from '../icon';
import { Text, TextColor, TextVariant } from '../typography';

export const Message: FC<MessageProps> = ({
    className,
    icon = IconType.Clear,
    title,
    subtitle,
}) => {
    const classes = cx('message', className);
    return (
        <div className={classes}>
            <Icon icon={icon} size={IconSize.XXLarge} color={IconColor.Blue} />
            <Text
                className="message__title"
                text={title}
                variant={TextVariant.LabelMedium}
                color={TextColor.White}
            />
            {subtitle && (
                <Text
                    className="message__subtitle"
                    text={subtitle}
                />
            )}
        </div>
    );
};

interface MessageProps {
    className?: string;
    icon?: IconType;
    title: string;
    subtitle?: string;
}
