/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-ignore
import { GeoJSONSource, SymbolLayout, SymbolPaint } from '!mapbox-gl';
import { FC, useContext, useEffect, useState } from 'react';
import { MapLayerProps } from '../../core/types';
import { MapContext } from './MapProvider';

export const MapMarker: FC<MapMarkerProps> = ({
    id,
    image,
    lat,
    lng,
    alignToMap = true,
    iconAnchor = 'center',
    rotate = 0,
    visible,
}) => {
    const { map } = useContext(MapContext);
    const [markerLoaded, setMarkerLoaded] = useState(false);
    useEffect(() => {
        if (map && !markerLoaded && visible) {
            map.loadImage(image, (error: any, image: any) => {
                if (error) throw error;
                map.addImage(`img-${id}`, image);
                map.addSource(id, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [lng, lat],
                                },
                                properties: {},
                            },
                        ],
                    },
                });
                map.addLayer({
                    id: `layer-${id}`,
                    type: 'symbol',
                    source: id,
                    layout: {
                        'icon-image': `img-${id}`,
                        'icon-anchor': iconAnchor,
                        'symbol-z-order': 'source',
                        'icon-rotate': 0,
                        ...(alignToMap
                            ? { 'icon-rotation-alignment': 'map' }
                            : {}),
                    } as SymbolLayout,
                    paint: {
                        'icon-opacity': 1,
                        'icon-opacity-transition': { duration: 0 },
                    } as SymbolPaint,
                });
            });
            setMarkerLoaded(true);
        }
    }, [map, visible]);
    useEffect(() => {
        if (map && markerLoaded && !visible) {
            map.removeLayer(`layer-${id}`);
            map.removeSource(id);
            map.removeImage(`img-${id}`);
            setMarkerLoaded(false);
        }
    }, [visible]);
    useEffect(() => {
        if (map && markerLoaded && visible) {
            if (rotate && rotate !== 0) {
                map.setLayoutProperty(
                    `layer-${id}`,
                    'icon-rotate',
                    rotate + 90,
                );
            }
            if (map.getSource(id) as GeoJSONSource) {
                (map.getSource(id) as GeoJSONSource).setData({
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Point',
                                coordinates: [lng, lat],
                            },
                        },
                    ],
                });
            }
        }
    }, [lat, lng, visible]);
    return null;
};

interface MapMarkerProps extends MapLayerProps {
    id: string;
    image: string;
    lat: number;
    lng: number;
    alignToMap?: boolean;
    iconAnchor?: 'center' | 'top' | 'bottom' | 'left' | 'right';
    rotate?: number;
}
