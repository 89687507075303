import cx from 'classnames';
import React, { FC, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { WrapWhen } from '../../utils';
import { Icon, IconColor, IconSize } from '../icon';
import { Text, TextColor, TextVariant } from '../typography';
import { MenuItem } from './Menu';

export const MenuButton: FC<MenuButtonProps> = ({ item }) => {
    const {
        icon,
        title,
        subtitle,
        link,
        onClick,
        isActive,
        isHorizontal,
    } = item;
    const handleClick = (e: MouseEvent) => {
        if (onClick) {
            onClick(e);
        }
        return e;
    };
    const buttonClass = cx('menu-button', {
        'menu-button--active': isActive,
        'menu-button--horizontal': isHorizontal,
    });
    return (
        <div className={buttonClass} onClick={handleClick}>
            <WrapWhen
                condition={!!link}
                wrapper={(children: ReactNode) => (
                    <Link to={link!}>{children}</Link>
                )}
            >
                <div className="menu-button__content">
                    <div className="menu-button__icon">
                        <Icon
                            icon={icon}
                            size={IconSize.XLarge}
                            color={isActive ? IconColor.Black : IconColor.Gray}
                        />
                    </div>
                    <div className="menu-button__label">
                        <Text
                            className="menu-button__title"
                            text={title}
                            variant={
                                isHorizontal
                                    ? TextVariant.BodyMedium
                                    : TextVariant.BodySmall
                            }
                            color={TextColor.White}
                        />
                        {subtitle && (
                            <Text
                                className="menu-button__subtitle"
                                text={subtitle}
                                variant={TextVariant.BodySmall}
                            />
                        )}
                    </div>
                </div>
            </WrapWhen>
        </div>
    );
};

interface MenuButtonProps {
    item: MenuItem;
}
