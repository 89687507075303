import { Dispatch } from 'redux';
import { history } from '..';
import {
    setAddressPreset,
    setCurrentPosition,
    SetCurrentPositionAction,
    setDestinationDetails,
    toggleBackgroundMode,
    toggleWidgetDriveMode,
    toggleWidgetUIComparison,
    updateWidgetGuidance,
} from './actions';
import {
    DestinationName,
    EventAction,
    EventActionType,
    ShowRouteEventPayload,
    UpdateDestinationDetailsEventPayload,
    UpdatePositionEventPayload,
    UpdateTurnByTurnGuidanceEventPayload,
} from './types';

interface IBRZNativeAPI {
    HTMLToIncari: (data: string) => void;
}

interface Window {
    BRZNativeAPI: IBRZNativeAPI;
    sendEvent: (data: string) => void;
}

/**
 * EventManager is reponsible for:
 * - sending events to Incari API
 * - responding to events coming from Incari API
 */
export class EventManager {
    private incariApi: IBRZNativeAPI | null;
    private window: Window;
    private dispatch: Dispatch;

    constructor(windowObject: any, reduxDispatch: any) {
        this.window = windowObject;
        this.dispatch = reduxDispatch;
        this.incariApi = this.window.BRZNativeAPI
            ? this.window.BRZNativeAPI
            : null;
    }

    /**
     * Registers event listener to the window object
     * Once registered, method sendEvent() should be used by Incari API
     * to send actions to the application. Actions are sent as stringified JSON objects
     * and parsed by this.handleEvent() method
     */
    public registerEventListener() {
        if (!this.window) {
            throw new Error('Cannot register event listener');
        }
        this.window.sendEvent = (data: string) => {
            try {
                this.handleEvent(JSON.parse(data));
            } catch (e) {
                console.error('Cannot parse event data', e);
            }
        };
    }

    /**
     * Used to send events between the application (navigation or widget)
     * through Incari API (API is responsible for forwarding the event to the target instance)
     */
    public sendEventToIncari(data: EventAction) {
        if (this.incariApi) {
            this.incariApi.HTMLToIncari(JSON.stringify(data));
        }
    }

    /**
     * This is a private method for handling the actual events within navigation (or widget) app
     * It uses Redux store to trigger actions based on the incoming events (passed via Incari API)
     */
    private handleEvent(event: EventAction) {
        switch (event.type) {
            // Enables/disables map visibility when not in the foreground
            case EventActionType.ToggleBackgroundMode: {
                this.dispatch(toggleBackgroundMode());
                break;
            }
            // Toggle driving mode (widget displays TBT while driving)
            case EventActionType.ToggleDriveMode: {
                this.dispatch(toggleWidgetDriveMode(event.payload));
                break;
            }
            // Updates position marker on the map
            case EventActionType.UpdatePosition: {
                const {
                    lat,
                    lng,
                    bearing,
                } = event.payload as UpdatePositionEventPayload;
                this.dispatch(
                    setCurrentPosition({
                        lat,
                        lng,
                        rotation: bearing,
                    }) as SetCurrentPositionAction,
                    // stopSimulation
                    // moveCamera({lat, lng});
                );
                break;
            }
            // Opens search screen with keyboard
            case EventActionType.OpenSearch: {
                history.push('/app/search');
                break;
            }
            // Updates one of the address presets
            case EventActionType.SetAddressPreset: {
                this.dispatch(setAddressPreset(event.payload));
                break;
            }
            // Show route for the selected address preset
            case EventActionType.ShowRoute: {
                const { preset } = event.payload as ShowRouteEventPayload;
                history.push(`/app/route/${preset}`);
                break;
            }
            case EventActionType.UpdateTurnByTurnGuidance: {
                const {
                    guidance,
                } = event.payload as UpdateTurnByTurnGuidanceEventPayload;
                this.dispatch(updateWidgetGuidance(guidance));
                break;
            }
            case EventActionType.UpdateDestinationRoutes: {
                const {
                    home,
                    work,
                } = event.payload as UpdateDestinationDetailsEventPayload;
                this.dispatch(
                    setDestinationDetails(DestinationName.Home, home),
                );
                this.dispatch(
                    setDestinationDetails(DestinationName.Work, work),
                );
                break;
            }
            case EventActionType.ToggleUIComparison: {
                this.dispatch(toggleWidgetUIComparison(event.payload));
                break;
            }
            default: {
                throw new Error(
                    `Event action handler not registered: ${event.type}`,
                );
            }
        }
    }
}
