import { IconType } from '../components/icon';
import { DrivingSide, ManeuverModifier, ManeuverType } from '../core/types';

export const getIconForManeuver = (
    type: ManeuverType,
    modifier: ManeuverModifier,
    drivingSide?: DrivingSide,
) => {
    const rightSide = drivingSide === 'right';
    switch (modifier) {
        case ManeuverModifier.Left: {
            switch (type) {
                case ManeuverType.Arrive: {
                    return IconType.ArriveLeft;
                }
                case ManeuverType.Depart: {
                    return IconType.DepartLeft;
                }
                case ManeuverType.EndOfRoad: {
                    return IconType.EndOfRoadLeft;
                }
                case ManeuverType.Fork: {
                    return IconType.ForkLeft;
                }
                case ManeuverType.OffRamp: {
                    return IconType.OffRampLeft;
                }
                case ManeuverType.Merge: {
                    return IconType.MergeLeft;
                }
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutLeft
                        : IconType.Roundabout2Left;
                }
                default: {
                    return IconType.Left;
                }
            }
        }
        case ManeuverModifier.Right: {
            switch (type) {
                case ManeuverType.Arrive: {
                    return IconType.ArriveRight;
                }
                case ManeuverType.Depart: {
                    return IconType.DepartRight;
                }
                case ManeuverType.EndOfRoad: {
                    return IconType.EndOfRoadRight;
                }
                case ManeuverType.Fork: {
                    return IconType.ForkRight;
                }
                case ManeuverType.OffRamp: {
                    return IconType.OffRampRight;
                }
                case ManeuverType.Merge: {
                    return IconType.MergeRight;
                }
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutRight
                        : IconType.Roundabout2Right;
                }
                default: {
                    return IconType.Right;
                }
            }
        }
        case ManeuverModifier.SharpLeft: {
            switch (type) {
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutSharpLeft
                        : IconType.Roundabout2SharpLeft;
                }
                default: {
                    return IconType.SharpLeft;
                }
            }
        }
        case ManeuverModifier.SharpRight: {
            switch (type) {
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutSharpRight
                        : IconType.Roundabout2SharpRight;
                }
                default: {
                    return IconType.SharpRight;
                }
            }
        }
        case ManeuverModifier.SlightLeft: {
            switch (type) {
                case ManeuverType.Fork: {
                    return IconType.ForkSlightLeft;
                }
                case ManeuverType.OffRamp: {
                    return IconType.OffRampSlightLeft;
                }
                case ManeuverType.Merge: {
                    return IconType.MergeSlightLeft;
                }
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutSlightLeft
                        : IconType.Roundabout2SlightLeft;
                }
                default: {
                    return IconType.SlightLeft;
                }
            }
        }
        case ManeuverModifier.SlightRight: {
            switch (type) {
                case ManeuverType.Fork: {
                    return IconType.ForkSlightRight;
                }
                case ManeuverType.OffRamp: {
                    return IconType.OffRampSlightRight;
                }
                case ManeuverType.Merge: {
                    return IconType.MergeSlightRight;
                }
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutSlightRight
                        : IconType.Roundabout2SlightRight;
                }
                default: {
                    return IconType.SlightRight;
                }
            }
        }
        case ManeuverModifier.Straight: {
            switch (type) {
                case ManeuverType.Fork: {
                    return IconType.ForkStraight;
                }
                case ManeuverType.Rotary:
                case ManeuverType.Roundabout:
                case ManeuverType.RoundaboutTurn: {
                    return rightSide
                        ? IconType.RoundaboutStraight
                        : IconType.Roundabout2Straight;
                }
                default: {
                    return IconType.Straight;
                }
            }
        }
        case ManeuverModifier.UTurn: {
            return IconType.UTurn;
        }
        default: {
            switch (type) {
                case ManeuverType.Arrive: {
                    return IconType.Arrive;
                }
                case ManeuverType.Depart: {
                    return IconType.Depart;
                }
                default: {
                    return IconType.Straight;
                }
            }
        }
    }
};
