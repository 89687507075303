export enum ManeuverType {
    Turn = 'turn',
    Merge = 'merge',
    Depart = 'depart',
    Arrive = 'arrive',
    Fork = 'fork',
    Roundabout = 'roundabout',
    NewName = 'new name',
    OnRamp = 'on ramp',
    OffRamp = 'off ramp',
    EndOfRoad = 'end of road',
    Continue = 'continue',
    Rotary = 'rotary',
    RoundaboutTurn = 'roundabout turn',
    Notification = 'notification',
    ExitRoundabout = 'exit roundabout',
    ExitRotary = 'exit rotary',
}

export enum ManeuverModifier {
    UTurn = 'uturn',
    SharpRight = 'sharp right',
    Right = 'right',
    SlightRight = 'slight right',
    Straight = 'straight',
    SlightLeft = 'slight left',
    Left = 'left',
    SharpLeft = 'sharp left',
};
