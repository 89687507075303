import React, { FC } from 'react';
import markerPosition from '../../assets/images/marker-position.png';
import { MapMarker } from '../../components';

export const PositionMarker: FC<PositionMarkerProps> = ({
    lat,
    lng,
    rotation = 0,
}) => {
    return (
        <MapMarker
            id="marker-position"
            image={markerPosition}
            lat={lat}
            lng={lng}
            rotate={rotation}
            visible
        />
    );
};

interface PositionMarkerProps {
    lat: number;
    lng: number;
    rotation?: number;
}
