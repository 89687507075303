import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import { InputRef, KeyboardKeyType, SpecialKeyType } from '../components';
import { splitAt } from '../utils/StringUtils';

const updateCaret = (ref: RefObject<InputRef>, pos: number) => {
    if (ref && ref.current) {
        setTimeout(() => {
            ref.current!.selectionStart = ref.current!.selectionEnd =
                pos < 0 ? 0 : pos;
        }, 0);
    }
};

export const useKeyboardTextInput = (initialValue: string = ''): any => {
    const inputRef = useRef<InputRef>(null);
    const [value, setValue] = useState<string>(initialValue);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        return;
    }, [value]);
    const handleKeyboardKeyPress = (char: KeyboardKeyType) => {
        if (!inputRef || !inputRef.current) {
            return;
        }
        const caretPosition = inputRef.current.selectionStart;
        const [left, right] = splitAt(value, caretPosition);
        if (typeof char === 'string') {
            setValue(`${left}${char}${right}`);
            updateCaret(inputRef, caretPosition + 1);
        } else {
            switch (char.type) {
                case SpecialKeyType.Backspace: {
                    setValue(`${left.slice(0, -1)}${right}`);
                    updateCaret(inputRef, caretPosition - 1);
                    break;
                }
                case SpecialKeyType.Space: {
                    setValue(`${left} ${right}`);
                    updateCaret(inputRef, caretPosition + 1);
                    break;
                }
                case SpecialKeyType.ArrowLeft: {
                    updateCaret(inputRef, caretPosition - 1);
                    break;
                }
                case SpecialKeyType.ArrowRight: {
                    updateCaret(inputRef, caretPosition + 1);
                    break;
                }
                default: {
                    console.error('NEED TO HANDLE', char.type);
                }
            }
        }
    };
    const handleInputClear = () => {
        setValue('');
    };
    const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    return [
        inputRef,
        value,
        handleInputValueChange,
        handleKeyboardKeyPress,
        handleInputClear,
    ];
};
