import { NavigationAction, NavigationActionType } from '../actions';
import { Route, RouteGuidance, RouteProgress } from '../types';

export interface NavigationState {
    routes: Route[];
    selectedRouteId: number;
    routeProgress: RouteProgress | null;
    guidance: RouteGuidance | null;
    error: boolean;
    simulationRunning: boolean;
    showRouteOverview: boolean;
}

const initialState: NavigationState = {
    routes: [],
    selectedRouteId: -1,
    routeProgress: null,
    guidance: null,
    error: false,
    simulationRunning: false,
    showRouteOverview: false,
};

export const navigationReducer = (
    state: NavigationState = initialState,
    action: NavigationAction,
): NavigationState => {
    switch (action.type) {
        case NavigationActionType.SetRoutes: {
            return {
                ...state,
                error: false,
                routes: action.payload,
                selectedRouteId: 0,
            };
        }
        case NavigationActionType.ToggleRoute: {
            return {
                ...state,
                selectedRouteId: action.payload,
            };
        }
        case NavigationActionType.ToggleError: {
            return {
                ...state,
                error: action.payload,
            };
        }
        case NavigationActionType.UpdateRouteProgress: {
            return {
                ...state,
                routeProgress: action.payload,
            };
        }
        case NavigationActionType.UpdateGuidance: {
            return {
                ...state,
                guidance: action.payload,
            };
        }
        case NavigationActionType.StartSimulation: {
            return {
                ...state,
                simulationRunning: true,
            };
        }
        case NavigationActionType.StopSimulation: {
            return {
                ...state,
                simulationRunning: false,
            };
        }
        case NavigationActionType.SetRouteOverview: {
            return {
                ...state,
                showRouteOverview: action.payload,
            };
        }
        case NavigationActionType.ClearRoute: {
            return {
                ...state,
                routes: [],
                selectedRouteId: -1,
                routeProgress: null,
                guidance: null,
                error: false,            
            }
        }
        default: {
            return state;
        }
    }
};
