import React, { FC } from 'react';
import { IconType, Message } from '../../components';

export const Offline: FC = () => {
    return (
        <div className="offline">
            <Message
                icon={IconType.Offline}
                title="You are offline"
                subtitle="Navigation requires an internet connection which is currently unavailable"
            />
        </div>
    );
};
