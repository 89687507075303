import { config } from '../../config';
import {
    NavigationAction,
    NavigationActionType,
    SearchAction,
    SearchActionType,
} from '../actions';
import { Destination, DestinationName, Feature } from '../types';
interface Destinations {
    [key: string]: Destination | null;
}
export interface SearchState {
    query: string;
    results: Feature[];
    destinations: Destinations;
    currentDestination: DestinationName | null;
}

const initialState: SearchState = {
    query: '',
    results: [],
    currentDestination: null,
    destinations: {
        [DestinationName.Home]: config.home,
        [DestinationName.Work]: config.work,
        [DestinationName.Custom]: null,
    },
};

export const searchReducer = (
    state: SearchState = initialState,
    action: SearchAction | NavigationAction,
): SearchState => {
    switch (action.type) {
        case SearchActionType.SetQuery: {
            return {
                ...state,
                query: action.payload,
            };
        }
        case SearchActionType.SetResults: {
            return {
                ...state,
                results: action.payload,
            };
        }
        case SearchActionType.ClearSearchResults: {
            return {
                ...state,
                currentDestination: null,
                results: [],
            };
        }
        case SearchActionType.SetCustomDestination: {
            return {
                ...state,
                destinations: {
                    ...state.destinations,
                    [DestinationName.Custom]: action.payload,
                },
            };
        }
        case SearchActionType.SelectDestination: {
            return {
                ...state,
                currentDestination: action.payload,
            };
        }
        case SearchActionType.UpdateAddressPreset: {
            const { preset, address1, address2, coordinates } = action.payload;
            return {
                ...state,
                destinations: {
                    ...state.destinations,
                    [preset]: {
                        address1,
                        address2,
                        coordinates,
                    },
                },
            };
        }
        case NavigationActionType.ClearRoute: {
            return {
                ...state,
                currentDestination: null,
            };
        }
        default: {
            return state;
        }
    }
};
