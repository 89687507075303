import { useDispatch, useSelector } from 'react-redux';
import {
    PositionMarkerOptions,
    resetCamera,
    setCurrentPosition,
    setFreeMode,
    toggleBackgroundMode,
    toggleBuildings,
    updateMapViewport,
    zoomIn,
    zoomOut,
} from '../core/actions';
import { CameraOptions } from '../core/types';
import { AppState } from '../reducer';

export const useMap = () => {
    const dispatch = useDispatch();
    const {
        mapViewport,
        position,
        bearing,
        zoom,
        offset,
        lat,
        lng,
        pitch,
        showBuildings,
        freeMode,
        backgroundMode,
        updateHash,
    } = useSelector((state: AppState) => state.map);
    return {
        setCurrentPosition: (options: PositionMarkerOptions) =>
            dispatch(setCurrentPosition(options)),
        updateMapViewport: (viewport: CameraOptions) =>
            dispatch(updateMapViewport(viewport)),
        zoomIn: () => dispatch(zoomIn()),
        zoomOut: () => dispatch(zoomOut()),
        resetCamera: () => dispatch(resetCamera()),
        setFreeMode: (mode: boolean) => dispatch(setFreeMode(mode)),
        toggleBuildings: () => dispatch(toggleBuildings()),
        toggleBackgroundMode: () => dispatch(toggleBackgroundMode()),
        bearing,
        zoom,
        offset,
        lat,
        lng,
        pitch,
        mapViewport,
        position,
        buildingsVisible: showBuildings,
        backgroundMode,
        freeMode,
        updateHash,
    };
};
