import cx from 'classnames';
import React, { FC } from 'react';
import { history } from '..';
import { Map, MapMask } from '../components';
import { MapBuildings } from '../components/map/MapBuildings';
import { useMap, useNavigation } from '../hooks';
import {
    DestinationMarker,
    MapControls,
    MapRoute,
    PositionMarker,
} from './components';

export const Navigation: FC<NavigationProps> = ({ className }) => {
    const classes = cx('navigation', className);
    const { position, buildingsVisible } = useMap();
    const { currentRoute, showRouteOverview } = useNavigation();
    const handleMapClick = () => {
        const pathname = window ? window.location.pathname : '';
        if (pathname === '/app/drive') {
            history.push('/app/drive/menu');
        } else if (pathname === '/app/drive/menu') {
            history.push('/app/drive');
        } else if (pathname === '/app/menu') {
            history.push('/app');
        } else if (pathname === '/app') {
            history.push('/app/menu');
        }
    };
    const geometry = currentRoute ? currentRoute.geometry : null;
    return (
        <div className={classes}>
            <MapMask>
                <Map onClick={handleMapClick}>
                    <MapRoute
                        geometry={geometry}
                        visible={!!geometry}
                        overviewMode={showRouteOverview}
                    />
                    <PositionMarker
                        lat={position.lat}
                        lng={position.lng}
                        rotation={position.rotation}
                    />
                    <DestinationMarker
                        geometry={geometry}
                        visible={!!geometry}
                    />
                    <MapBuildings visible={buildingsVisible} />
                </Map>
                <MapControls className="navigation__controls" />
            </MapMask>
        </div>
    );
};

interface NavigationProps {
    className?: string;
}
