import React, { FC, MouseEvent } from 'react';
import {
    ButtonBase,
    ButtonBaseProps,
    ButtonSize,
    ButtonVariant,
    getButtonClasses,
} from './ButtonBase';

export const Button: FC<ButtonBaseProps> = ({
    icon,
    iconColor,
    iconSize,
    size = ButtonSize.Medium,
    label = '',
    disabled = false,
    busy = false,
    buttonType = 'button',
    onClick,
    className,
    children,
    hint,
    dotted = false,
    variant = ButtonVariant.Secondary,
}) => {
    const handleClick = (event: MouseEvent) => {
        if (!disabled && !busy && onClick) {
            onClick(event);
        }
        return;
    };
    const renderedLabel: string = busy ? 'Please wait...' : label;
    return (
        <button
            type={buttonType}
            className={getButtonClasses(size, disabled, dotted, className)}
            onClick={handleClick}
            disabled={disabled}
            title={hint}
            aria-label={hint || label}
        >
            <ButtonBase
                icon={icon}
                iconColor={iconColor}
                iconSize={iconSize}
                label={renderedLabel}
                variant={variant}
            >
                {children}
            </ButtonBase>
        </button>
    );
};
