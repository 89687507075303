export enum EventSource {
    Incari = 'INCARI',
    Widget = 'WIDGET',
    Navigation = 'NAVIGATION',
}

export enum EventTarget {
    Widget = 'WIDGET',
    Navigation = 'NAVIGATION',
}

export enum EventActionType {
    ToggleBackgroundMode = 'TOGGLE_BACKGROUND_MODE',
    ToggleDriveMode = 'TOGGLE_DRIVE_MODE',
    UpdatePosition = 'UPDATE_POSITION',
    SetAddressPreset = 'SET_ADDRESS_PRESET',
    ShowRoute = 'SHOW_ROUTE',
    OpenSearch = 'OPEN_SEARCH',
    UpdateDestinationRoutes = 'UPDATE_DESTINATION_ROUTES',
    UpdateTurnByTurnGuidance = 'UPDATE_TBT_GUIDANCE',
    ToggleUIComparison = 'TOGGLE_UI_COMPARISON',
}

export interface EventAction {
    type: EventActionType;
    source: EventSource;
    target: EventTarget;
    payload?: any;
}
