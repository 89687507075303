import cx from 'classnames';
import React, { FC, MouseEventHandler } from 'react';
import { IconType } from '../icon';
import { MenuSection } from './MenuSection';

export const Menu: FC<MenuProps> = ({ className, sections }) => {
    const menuClass = cx('menu', className);
    return (
        <div className={menuClass}>
            {sections &&
                sections.map(({ title, items }) => {
                    return (
                        <MenuSection
                            key={`menu-item-${title}`}
                            className="menu__section"
                            title={title}
                            items={items}
                        />
                    );
                })}
        </div>
    );
};

export interface MenuItem {
    icon: IconType;
    title: string;
    subtitle?: string;
    link?: string;
    isActive?: boolean;
    isHorizontal?: boolean;
    onClick?: MouseEventHandler;
}

export interface MenuItemSection {
    items: MenuItem[];
    title?: string;
}

export interface MenuProps {
    className?: string;
    sections: MenuItemSection[];
}
