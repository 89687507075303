import { Feature } from '../types';
import { MapBoxApiService } from './MapBoxApiService';

interface GeoCodingApiResponse {
    attribution: string;
    features: Feature[];
    query: string[];
    type: 'FeatureCollection';
}

class GeoCodingService extends MapBoxApiService {
    protected serviceUrl = 'geocoding/v5/mapbox.places';
    public async search(query: string): Promise<Feature[]> {
        try {
            const result = await this.get(`/${query}.json`);
            if (result.success) {
                return (result.data as GeoCodingApiResponse).features;
            }
        } catch (e) {
            console.error(e);
        }
        return [];
    }
}

export const geoCodingService = new GeoCodingService();
