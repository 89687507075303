import React, { FC } from 'react';
import {
    Actions,
    ButtonVariant,
    Text,
    TextColor,
    TextVariant,
} from '../../components';
import { RouteStats } from './RouteStats';

export const RouteSummaryDetails: FC<RouteSummaryDetailsProps> = ({
    onStart,
    onContinue,
    onChangeRoute,
    routesCount,
    simulationRunning,
    address1,
    address2,
}) => {
    return (
        <div className="route-summary-details">
            <div className="route-summary-details__address">
                <Text
                    color={TextColor.White}
                    variant={TextVariant.BodyLarge}
                    text={address1}
                    overflowEllipsis
                />
                <Text text={address2} overflowEllipsis />
            </div>
            <div className="route-summary-details__counters">
                <RouteStats />
            </div>
            <Actions
                className="route-summary-details__actions"
                buttons={
                    simulationRunning
                        ? [
                              {
                                  label: 'Continue',
                                  onClick: onContinue,
                                  variant: ButtonVariant.Primary,
                              },
                          ]
                        : [
                              {
                                  label: 'Start',
                                  onClick: onStart,
                                  variant: ButtonVariant.Primary,
                              },
                              ...(routesCount > 1
                                  ? [
                                        {
                                            label: 'Next route',
                                            onClick: onChangeRoute,
                                            hidden: routesCount <= 1,
                                        },
                                    ]
                                  : []),
                          ]
                }
            />
        </div>
    );
};

interface RouteSummaryDetailsProps {
    onStart: () => void;
    onContinue: () => void;
    onChangeRoute: () => void;
    routesCount: number;
    simulationRunning: boolean;
    address1: string;
    address2: string;
}
