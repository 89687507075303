import React, { FC } from 'react';
import { useNavigation } from '../../hooks';
import { RouteStats } from '../../routes';
import { Guidance } from './Guidance';

export const TurnByTurn: FC = () => {
    const { guidance, simulationRunning } = useNavigation();
    if (!simulationRunning || !guidance || !guidance.instructions) {
        return null;
    }
    return (
        <div className="turn-by-turn">
            <Guidance data={guidance} />
            <div className="turn-by-turn__counters">
                <RouteStats />
            </div>
        </div>
    );
};
