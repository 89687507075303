import React, { FC } from 'react';
import { Icon, IconColor, IconSize } from '../../components';
import { DrivingSide, ManeuverModifier, ManeuverType } from '../../core/types';
import { getIconForManeuver } from '../../utils';

export const Maneuver: FC<ManeuverProps> = ({ type, modifier, drivingSide }) => {
    return (
        <div className="maneuver">
            <Icon
                className="maneuver__icon"
                icon={getIconForManeuver(type, modifier, drivingSide)}
                size={IconSize.XXXLarge}
                color={IconColor.Original}
            />
        </div>
    );
};

interface ManeuverProps {
    type: ManeuverType;
    modifier: ManeuverModifier;
    drivingSide?: DrivingSide;
}
