import React, { FC } from 'react';
import { Counter } from '../../components';
import { useNavigation } from '../../hooks';
import { formatArrival, formatDistance, formatTimeLeft } from '../../utils';

export const RouteStats: FC = () => {
    const { currentRoute, routeProgress } = useNavigation();
    if (!currentRoute) {
        return null;
    }
    const { distance: d, duration: t } = currentRoute;
    const distance = routeProgress ? d - routeProgress.distanceTravelled : d;
    const duration = routeProgress ? t - routeProgress.timeElapsed : t;
    return (
        <div className="route-stats">
            <Counter
                className="route-stats__counter"
                label="Arrival"
                value={formatArrival(duration)}
            />
            <Counter
                className="route-stats__counter"
                label="Time left"
                value={formatTimeLeft(duration)}
            />
            <Counter
                className="route-stats__counter"
                label="Distance"
                value={formatDistance(distance, false)}
            />
        </div>
    );
};
