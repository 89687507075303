import cx from 'classnames';
import React, { FC } from 'react';

export interface ImageProps {
    source: string;
    className?: string;
    alt?: string;
}

export const Image: FC<ImageProps> = ({ source, className, alt = '' }) => {
    const imageClass = cx('image', className);
    return (
        <div className={imageClass}>
            <img className="image__picture" src={source} alt={alt} />
        </div>
    );
};
