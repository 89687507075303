import cx from 'classnames';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ButtonSize,
    IconColor,
    IconSize,
    IconType,
} from '../../components';
import { useMap, useNavigation } from '../../hooks';

export const MapControls: FC<MapControlsProps> = ({ className }) => {
    const controlsClass = cx('map-controls', className);
    const { zoomIn, zoomOut, freeMode, resetCamera, setFreeMode } = useMap();
    const {
        simulationRunning,
        showRouteOverview,
        setRouteOverview,
    } = useNavigation();
    const history = useHistory();
    const handleZoomIn = () => {
        zoomIn();
    };
    const handleZoomOut = () => {
        zoomOut();
    };
    const handleReCenter = () => {
        resetCamera();
    };
    const handleShowSummary = () => {
        setFreeMode(true);
        setRouteOverview(true);
        history.push('/app/drive/route');
    };
    return !showRouteOverview ? (
        <div className={controlsClass}>
            {simulationRunning && (
                <Button
                    className="map-controls__button"
                    icon={IconType.Route}
                    onClick={handleShowSummary}
                    size={ButtonSize.Medium}
                    iconSize={IconSize.Medium}
                    dotted
                />
            )}
            <Button
                className="map-controls__button"
                icon={freeMode ? IconType.CompassMoved : IconType.Compass}
                iconColor={freeMode ? IconColor.Original : IconColor.Gray}
                onClick={handleReCenter}
                size={ButtonSize.Medium}
                iconSize={IconSize.Medium}
                dotted
            />
            <div className="map-controls__zoom">
                <Button
                    className="map-controls__zoom-button"
                    icon={IconType.ZoomIn}
                    onClick={handleZoomIn}
                    size={ButtonSize.Medium}
                    iconSize={IconSize.Medium}
                    dotted
                />
                <Button
                    className="map-controls__zoom-button"
                    icon={IconType.ZoomOut}
                    onClick={handleZoomOut}
                    size={ButtonSize.Medium}
                    iconSize={IconSize.Medium}
                    dotted
                />
            </div>
        </div>
    ) : null;
};

interface MapControlsProps {
    className?: string;
}
