import { Coordinates } from './Coordinates';

export enum DestinationName {
    Home = 'home',
    Work = 'work',
    Custom = 'custom',
}

export interface Destination {
    coordinates: Coordinates;
    address1: string;
    address2: string;
}

export interface DestinationDetails {
    duration: number;
    distance: number;
}
