import React, { FC } from 'react';
import { IconType, Menu } from '../../components';
import {
    DestinationDetails,
    DestinationName,
    EventActionType,
    EventSource,
    EventTarget,
} from '../../core/types';
import { useNetworkConnection, useWidget } from '../../hooks';
import { eventManager } from '../../index';
import { formatDistance, formatDuration } from '../../utils';

export const WidgetMenu: FC = () => {
    const handleRouteSelect = (name: DestinationName) => () => {
        eventManager.sendEventToIncari({
            source: EventSource.Widget,
            target: EventTarget.Navigation,
            type: EventActionType.ShowRoute,
            payload: {
                preset: name,
            },
        });
    };
    const online = useNetworkConnection();
    const { destinationDetails } = useWidget();
    const renderRouteSummary = (
        preset: DestinationName.Home | DestinationName.Work,
    ) => {
        if (!online) {
            return 'Offline';
        }
        const detailsToString = ({
            duration,
            distance,
        }: DestinationDetails): string => {
            return `${formatDuration(
                duration,
                true,
            )}&nbsp;&nbsp;&nbsp;${formatDistance(distance, false)}`;
        };
        return destinationDetails[preset] !== null
            ? detailsToString(destinationDetails[preset] as any)
            : 'Calculating';
    };
    return (
        <Menu
            className="widget-menu"
            sections={[
                {
                    items: [
                        {
                            icon: IconType.Home,
                            title: 'Home',
                            subtitle: renderRouteSummary(DestinationName.Home),
                            isHorizontal: true,
                            onClick: handleRouteSelect(DestinationName.Home),
                        },
                        {
                            icon: IconType.Work,
                            title: 'Work',
                            subtitle: renderRouteSummary(DestinationName.Work),
                            isHorizontal: true,
                            onClick: handleRouteSelect(DestinationName.Work),
                        },
                    ],
                },
            ]}
        />
    );
};
