import cx from 'classnames';
import React, { FC, SyntheticEvent } from 'react';
import { KeyboardSpecialKey } from './KeyboardLayout';

export type KeyboardKeyType = string | KeyboardSpecialKey;

export const isSpecialKey = (char: KeyboardKeyType) =>
    !!(char as KeyboardSpecialKey).type;

export const KeyboardKey: FC<KeyboardKeyProps> = ({
    char,
    onClick,
    className,
}) => {
    const handleKeyClick = (char: KeyboardKeyType) => (e: SyntheticEvent) => {
        if (onClick) {
            onClick(char);
        }
        e.preventDefault();
    };
    const keyClass = cx('keyboard-key', className);
    return (
        <div className={keyClass} onMouseDown={handleKeyClick(char)}>
            {typeof char === 'string'
                ? (char as string)
                : (char as KeyboardSpecialKey).render}
        </div>
    );
};

interface KeyboardKeyProps {
    char: KeyboardKeyType;
    onClick: (c: KeyboardKeyType) => void;
    className?: string;
}
