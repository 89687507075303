import cx from 'classnames';
import React, { FC, forwardRef, InputHTMLAttributes, Ref } from 'react';
import { Button } from '../button';
import { IconColor, IconSize, IconType } from '../icon';

export const Input: FC<InputProps> = forwardRef(
    (props: InputProps, ref: Ref<InputRef>) => {
        const { className, placeholder, clearable, onClear, ...rest } = props;
        const classes = cx('input', className);
        const handleClear = () => {
            if (onClear) {
                onClear();
            }
        };
        return (
            <div className={classes}>
                <input
                    type="text"
                    className="input__control"
                    placeholder={placeholder}
                    {...rest}
                    ref={ref as any}
                    autoCorrect="off"
                    spellCheck={false}
                />
                {clearable && (
                    <Button
                        icon={IconType.Clear}
                        iconColor={IconColor.Gray}
                        iconSize={IconSize.Medium}
                        onClick={handleClear}
                    />
                )}
            </div>
        );
    },
);

export interface InputRef {
    selectionStart: number;
    selectionEnd: number;
    focus: () => void;
    dispatchEvent: (event: Event) => boolean;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    className?: string;
    ref: Ref<InputRef>;
    clearable: boolean;
    onClear?: () => void;
}
