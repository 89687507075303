/* eslint-disable react-hooks/exhaustive-deps */
import cx from 'classnames';
import React, { FC, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { MapProvider } from '../../components/map';
import { Drive } from '../../drive';
import {
    useMap,
    useNavigation,
    useNetworkConnection,
    useSearch,
} from '../../hooks';
import { Navigation } from '../../navigation';
import { Routes } from '../../routes';
import { Search } from '../../search';
import { MainMenu } from './MainMenu';
import { Panel } from './Panel';

export const Main: FC = () => {
    const { mapViewport, backgroundMode } = useMap();
    const online = useNetworkConnection();
    const { getSimplePresetDirections } = useNavigation();
    const { destinations } = useSearch();
    const { pathname } = useLocation();
    const panelOpen = pathname !== '/app';
    const isFullOverlay = pathname === '/app/search';
    const classes = cx('main', {
        'main--panel-open': panelOpen,
        'main--background-mode': backgroundMode,
    });
    useEffect(() => {
        getSimplePresetDirections();
    }, [destinations, online]);
    return (
        <div className={classes}>
            <Panel isOpen={panelOpen} fullSize={isFullOverlay}>
                <Switch>
                    <Route path="/app/menu">
                        <MainMenu />
                        {/* <CSSTransition
                            timeout={300}
                            classNames="page"
                        >

                        </CSSTransition> */}
                    </Route>
                    <Route path="/app/search">
                        <Search />
                    </Route>
                    <Route path="/app/route">
                        <Routes />
                    </Route>
                    <Route path="/app/drive">
                        <Drive />
                    </Route>
                </Switch>
            </Panel>
            <MapProvider viewport={mapViewport}>
                <Navigation className="main__navigation" />
            </MapProvider>
        </div>
    );
};
