// import { DestinationDetails } from '../types';
import { DestinationDetails, DestinationName, RouteGuidance } from '../types';
import { WidgetActionType } from './widgetActionTypes';

export const setDestinationDetails = (
    name: DestinationName,
    details: DestinationDetails,
) => ({
    type: WidgetActionType.SetDestinationDetails,
    payload: {
        name,
        details,
    },
});

export const updateWidgetGuidance = (guidance: RouteGuidance) => ({
    type: WidgetActionType.UpdateGuidance,
    payload: guidance,
});

export const toggleWidgetDriveMode = (state: boolean) => ({
    type: WidgetActionType.ToggleDriveMode,
    payload: state,
});

export const toggleWidgetUIComparison = (state: boolean) => ({
    type: WidgetActionType.ToggleUIComparison,
    payload: state,
});
