import React, { FC, MouseEvent } from 'react';
import { Text, TextColor, TextVariant } from '../../components';
import { Feature } from '../../core/types';

export const SearchResults: FC<SearchResultsProps> = ({
    results,
    onSelectResult,
}) => {
    const handleItemClick = (index: number) => (event: MouseEvent) => {
        if (onSelectResult) {
            onSelectResult(index);
        }
        return event;
    };
    return (
        <div className="search-results">
            <ul className="search-results__list">
                {results &&
                    results.map((feature: Feature, index: number) => (
                        <li
                            className="search-results__item"
                            key={`search-result-${index}`}
                            onClick={handleItemClick(index)}
                        >
                            <Text
                                text={feature.place_name}
                                variant={TextVariant.BodyMedium}
                                color={TextColor.Gray}
                                overflowEllipsis
                            />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

interface SearchResultsProps {
    results: Feature[];
    onSelectResult: (resultId: number) => void;
}
