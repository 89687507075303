import { ReactComponent as IconAdd } from '../../assets/icons/add.svg';
import { ReactComponent as IconBack } from '../../assets/icons/back.svg';
import { ReactComponent as IconBackspace } from '../../assets/icons/backspace.svg';
import { ReactComponent as IconBuildings } from '../../assets/icons/buildings.svg';
import { ReactComponent as IconClear } from '../../assets/icons/clear.svg';
import { ReactComponent as IconCompassMoved } from '../../assets/icons/compass-moved.svg';
import { ReactComponent as IconCompass } from '../../assets/icons/compass.svg';
import { ReactComponent as IconHome } from '../../assets/icons/home.svg';
import { ReactComponent as ManeuverArriveLeft } from '../../assets/icons/maneuvers/arrive-left.svg';
import { ReactComponent as ManeuverArriveRight } from '../../assets/icons/maneuvers/arrive-right.svg';
import { ReactComponent as ManeuverArrive } from '../../assets/icons/maneuvers/arrive.svg';
import { ReactComponent as ManeuverDepartLeft } from '../../assets/icons/maneuvers/depart-left.svg';
import { ReactComponent as ManeuverDepartRight } from '../../assets/icons/maneuvers/depart-right.svg';
import { ReactComponent as ManeuverDepart } from '../../assets/icons/maneuvers/depart.svg';
import { ReactComponent as ManeuverEndOfRoadLeft } from '../../assets/icons/maneuvers/end-of-road-left.svg';
import { ReactComponent as ManeuverEndOfRoadRight } from '../../assets/icons/maneuvers/end-of-road-right.svg';
import { ReactComponent as ManeuverForkLeft } from '../../assets/icons/maneuvers/fork-left.svg';
import { ReactComponent as ManeuverForkRight } from '../../assets/icons/maneuvers/fork-right.svg';
import { ReactComponent as ManeuverForkSlightLeft } from '../../assets/icons/maneuvers/fork-slight-left.svg';
import { ReactComponent as ManeuverForkSlightRight } from '../../assets/icons/maneuvers/fork-slight-right.svg';
import { ReactComponent as ManeuverForkStraight } from '../../assets/icons/maneuvers/fork-straight.svg';
import { ReactComponent as ManeuverFork } from '../../assets/icons/maneuvers/fork.svg';
import { ReactComponent as ManeuverLeft } from '../../assets/icons/maneuvers/left.svg';
import { ReactComponent as ManeuverMergeLeft } from '../../assets/icons/maneuvers/merge-left.svg';
import { ReactComponent as ManeuverMergeRight } from '../../assets/icons/maneuvers/merge-right.svg';
import { ReactComponent as ManeuverMergeSlightLeft } from '../../assets/icons/maneuvers/merge-slight-left.svg';
import { ReactComponent as ManeuverMergeSlightRight } from '../../assets/icons/maneuvers/merge-slight-right.svg';
import { ReactComponent as ManeuverOffRampLeft } from '../../assets/icons/maneuvers/off-ramp-left.svg';
import { ReactComponent as ManeuverOffRampRight } from '../../assets/icons/maneuvers/off-ramp-right.svg';
import { ReactComponent as ManeuverOffRampSlightLeft } from '../../assets/icons/maneuvers/off-ramp-slight-left.svg';
import { ReactComponent as ManeuverOffRampSlightRight } from '../../assets/icons/maneuvers/off-ramp-slight-right.svg';
import { ReactComponent as ManeuverRight } from '../../assets/icons/maneuvers/right.svg';
import { ReactComponent as ManeuverRoundaboutLeft } from '../../assets/icons/maneuvers/roundabout-left.svg';
import { ReactComponent as ManeuverRoundaboutRight } from '../../assets/icons/maneuvers/roundabout-right.svg';
import { ReactComponent as ManeuverRoundaboutSharpLeft } from '../../assets/icons/maneuvers/roundabout-sharp-left.svg';
import { ReactComponent as ManeuverRoundaboutSharpRight } from '../../assets/icons/maneuvers/roundabout-sharp-right.svg';
import { ReactComponent as ManeuverRoundaboutSlightLeft } from '../../assets/icons/maneuvers/roundabout-slight-left.svg';
import { ReactComponent as ManeuverRoundaboutSlightRight } from '../../assets/icons/maneuvers/roundabout-slight-right.svg';
import { ReactComponent as ManeuverRoundaboutStraight } from '../../assets/icons/maneuvers/roundabout-straight.svg';
import { ReactComponent as ManeuverRoundabout } from '../../assets/icons/maneuvers/roundabout.svg';
import { ReactComponent as ManeuverRoundabout2Left } from '../../assets/icons/maneuvers/roundabout2-left.svg';
import { ReactComponent as ManeuverRoundabout2Right } from '../../assets/icons/maneuvers/roundabout2-right.svg';
import { ReactComponent as ManeuverRoundabout2SharpLeft } from '../../assets/icons/maneuvers/roundabout2-sharp-left.svg';
import { ReactComponent as ManeuverRoundabout2SharpRight } from '../../assets/icons/maneuvers/roundabout2-sharp-right.svg';
import { ReactComponent as ManeuverRoundabout2SlightLeft } from '../../assets/icons/maneuvers/roundabout2-slight-left.svg';
import { ReactComponent as ManeuverRoundabout2SlightRight } from '../../assets/icons/maneuvers/roundabout2-slight-right.svg';
import { ReactComponent as ManeuverRoundabout2Straight } from '../../assets/icons/maneuvers/roundabout2-straight.svg';
import { ReactComponent as ManeuverRoundabout2 } from '../../assets/icons/maneuvers/roundabout2.svg';
import { ReactComponent as ManeuverSharpLeft } from '../../assets/icons/maneuvers/sharp-left.svg';
import { ReactComponent as ManeuverSharpRight } from '../../assets/icons/maneuvers/sharp-right.svg';
import { ReactComponent as ManeuverSlightLeft } from '../../assets/icons/maneuvers/slight-left.svg';
import { ReactComponent as ManeuverSlightRight } from '../../assets/icons/maneuvers/slight-right.svg';
import { ReactComponent as ManeuverStraight } from '../../assets/icons/maneuvers/straight.svg';
import { ReactComponent as ManeuverUTurn } from '../../assets/icons/maneuvers/u-turn.svg';
import { ReactComponent as IconNext } from '../../assets/icons/next.svg';
import { ReactComponent as IconNorth } from '../../assets/icons/north.svg';
import { ReactComponent as IconOfflineAlt } from '../../assets/icons/offline-alt.svg';
import { ReactComponent as IconOffline } from '../../assets/icons/offline.svg';
import { ReactComponent as IconPrev } from '../../assets/icons/prev.svg';
import { ReactComponent as IconRecent } from '../../assets/icons/recent.svg';
import { ReactComponent as IconRoute } from '../../assets/icons/route.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/search.svg';
import { ReactComponent as IconSettings } from '../../assets/icons/settings.svg';
import { ReactComponent as IconTraffic } from '../../assets/icons/traffic.svg';
import { ReactComponent as IconVolume } from '../../assets/icons/volume.svg';
import { ReactComponent as IconWork } from '../../assets/icons/work.svg';
import { ReactComponent as IconZoomIn } from '../../assets/icons/zoom-in.svg';
import { ReactComponent as IconZoomOut } from '../../assets/icons/zoom-out.svg';

type MapIconTypeToIcon = {
    [key in IconType]: any;
};

export enum IconType {
    Add,
    Back,
    Compass,
    CompassMoved,
    Route,
    Home,
    Recent,
    Search,
    Settings,
    Work,
    ZoomIn,
    ZoomOut,
    Backspace,
    Next,
    Prev,
    Clear,
    Volume,
    NorthUp,
    Buildings,
    Traffic,
    ArriveLeft,
    ArriveRight,
    Arrive,
    DepartLeft,
    DepartRight,
    Depart,
    EndOfRoadLeft,
    EndOfRoadRight,
    ForkLeft,
    ForkRight,
    ForkSlightLeft,
    ForkSlightRight,
    ForkStraight,
    Fork,
    Left,
    MergeLeft,
    MergeRight,
    MergeSlightLeft,
    MergeSlightRight,
    OffRampLeft,
    OffRampRight,
    OffRampSlightLeft,
    OffRampSlightRight,
    Right,
    SharpLeft,
    SharpRight,
    SlightLeft,
    SlightRight,
    Straight,
    UTurn,
    RoundaboutLeft,
    RoundaboutRight,
    RoundaboutSharpLeft,
    RoundaboutSharpRight,
    RoundaboutSlightLeft,
    RoundaboutSlightRight,
    RoundaboutStraight,
    Roundabout,
    Roundabout2Left,
    Roundabout2Right,
    Roundabout2SharpLeft,
    Roundabout2SharpRight,
    Roundabout2SlightLeft,
    Roundabout2SlightRight,
    Roundabout2Straight,
    Roundabout2,
    Offline,
    OfflineAlt,
}

export const getIcon = (icon: IconType) =>
    (({
        [IconType.Add]: IconAdd,
        [IconType.Back]: IconBack,
        [IconType.Compass]: IconCompass,
        [IconType.CompassMoved]: IconCompassMoved,
        [IconType.Route]: IconRoute,
        [IconType.Home]: IconHome,
        [IconType.Recent]: IconRecent,
        [IconType.Search]: IconSearch,
        [IconType.Settings]: IconSettings,
        [IconType.Work]: IconWork,
        [IconType.ZoomIn]: IconZoomIn,
        [IconType.ZoomOut]: IconZoomOut,
        [IconType.Backspace]: IconBackspace,
        [IconType.Next]: IconNext,
        [IconType.Prev]: IconPrev,
        [IconType.Clear]: IconClear,
        [IconType.Buildings]: IconBuildings,
        [IconType.Volume]: IconVolume,
        [IconType.Traffic]: IconTraffic,
        [IconType.NorthUp]: IconNorth,
        [IconType.ArriveLeft]: ManeuverArriveLeft,
        [IconType.ArriveRight]: ManeuverArriveRight,
        [IconType.Arrive]: ManeuverArrive,
        [IconType.DepartLeft]: ManeuverDepartLeft,
        [IconType.DepartRight]: ManeuverDepartRight,
        [IconType.Depart]: ManeuverDepart,
        [IconType.EndOfRoadLeft]: ManeuverEndOfRoadLeft,
        [IconType.EndOfRoadRight]: ManeuverEndOfRoadRight,
        [IconType.ForkLeft]: ManeuverForkLeft,
        [IconType.ForkRight]: ManeuverForkRight,
        [IconType.ForkSlightLeft]: ManeuverForkSlightLeft,
        [IconType.ForkSlightRight]: ManeuverForkSlightRight,
        [IconType.ForkStraight]: ManeuverForkStraight,
        [IconType.Fork]: ManeuverFork,
        [IconType.Left]: ManeuverLeft,
        [IconType.MergeLeft]: ManeuverMergeLeft,
        [IconType.MergeRight]: ManeuverMergeRight,
        [IconType.MergeSlightLeft]: ManeuverMergeSlightLeft,
        [IconType.MergeSlightRight]: ManeuverMergeSlightRight,
        [IconType.OffRampLeft]: ManeuverOffRampLeft,
        [IconType.OffRampRight]: ManeuverOffRampRight,
        [IconType.OffRampSlightLeft]: ManeuverOffRampSlightLeft,
        [IconType.OffRampSlightRight]: ManeuverOffRampSlightRight,
        [IconType.Right]: ManeuverRight,
        [IconType.SharpLeft]: ManeuverSharpLeft,
        [IconType.SharpRight]: ManeuverSharpRight,
        [IconType.SlightLeft]: ManeuverSlightLeft,
        [IconType.SlightRight]: ManeuverSlightRight,
        [IconType.Straight]: ManeuverStraight,
        [IconType.UTurn]: ManeuverUTurn,
        [IconType.RoundaboutLeft]: ManeuverRoundaboutLeft,
        [IconType.RoundaboutRight]: ManeuverRoundaboutRight,
        [IconType.RoundaboutSharpLeft]: ManeuverRoundaboutSharpLeft,
        [IconType.RoundaboutSharpRight]: ManeuverRoundaboutSharpRight,
        [IconType.RoundaboutSlightLeft]: ManeuverRoundaboutSlightLeft,
        [IconType.RoundaboutSlightRight]: ManeuverRoundaboutSlightRight,
        [IconType.RoundaboutStraight]: ManeuverRoundaboutStraight,
        [IconType.Roundabout]: ManeuverRoundabout,
        [IconType.Roundabout2Left]: ManeuverRoundabout2Left,
        [IconType.Roundabout2Right]: ManeuverRoundabout2Right,
        [IconType.Roundabout2SharpLeft]: ManeuverRoundabout2SharpLeft,
        [IconType.Roundabout2SharpRight]: ManeuverRoundabout2SharpRight,
        [IconType.Roundabout2SlightLeft]: ManeuverRoundabout2SlightLeft,
        [IconType.Roundabout2SlightRight]: ManeuverRoundabout2SlightRight,
        [IconType.Roundabout2Straight]: ManeuverRoundabout2Straight,
        [IconType.Roundabout2]: ManeuverRoundabout2,
        [IconType.Offline]: IconOffline,
        [IconType.OfflineAlt]: IconOfflineAlt,
    } as MapIconTypeToIcon)[icon] || null);
