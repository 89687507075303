import { Dispatch } from 'redux';
import { geoCodingService } from '../services/GeoCodingService';
import {
    Destination,
    DestinationName,
    Feature,
    SetAddressPresetEventPayload,
} from '../types';
import { SearchActionType } from './searchActionTypes';

const searchAction = (query: string) => ({
    type: SearchActionType.SetQuery,
    payload: query,
});

const setSearchResults = (results: Feature[]) => ({
    type: SearchActionType.SetResults,
    payload: results,
});

const setCustomDestination = (destination: Destination) => ({
    type: SearchActionType.SetCustomDestination,
    payload: destination,
});

export const setAddressPreset = (address: SetAddressPresetEventPayload) => ({
    type: SearchActionType.UpdateAddressPreset,
    payload: address,
});

export const selectDestination = (name: DestinationName) => ({
    type: SearchActionType.SelectDestination,
    payload: name,
});

export const clearSearchResults = () => ({
    type: SearchActionType.ClearSearchResults,
});

export const setFeatureAsCustomDestination = (feature: Feature) => async (
    dispatch: Dispatch,
) => {
    const { properties, geometry, text, place_name } = feature;
    const [lng, lat] = geometry.coordinates;
    dispatch(
        setCustomDestination({
            address1: text,
            address2:
                properties && properties.address
                    ? properties.address
                    : place_name,
            coordinates: { lat, lng },
        }),
    );
};

export const search = (query: string) => async (dispatch: Dispatch) => {
    if (query !== '') {
        dispatch(searchAction(query));
        const features = await geoCodingService.search(query);
        dispatch(setSearchResults(features));
    }
};
