import cx from 'classnames';
import React, { FC } from 'react';

export enum TextVariant {
    BodyXSmall = 'body-xsmall',
    BodySmall = 'body-small',
    BodyMedium = 'body-medium',
    BodyLarge = 'body-large',
    LabelSmall = 'label-small',
    LabelMedium = 'label-medium',
    LabelLarge = 'label-large',
    LabelXLarge = 'label-xlarge',
}

export enum TextColor {
    White = 'white',
    Gray = 'gray',
    Blue = 'blue',
    Red = 'red',
    Gold = 'gold',
}

export const Text: FC<TextProps> = ({
    as = 'p',
    text,
    variant = TextVariant.BodyMedium,
    color = TextColor.Gray,
    className,
    overflowEllipsis = false,
}) => {
    const TextElement = as;
    const textClass = cx(
        'text',
        `text--variant-${variant}`,
        `text--color-${color}`,
        { 'text--ellipsis': overflowEllipsis },
        className,
    );
    return (
        <TextElement
            className={textClass}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
};

export type TextElementType = 'p' | 'span' | 'div' | 'li';

export interface TextProps {
    text: string;
    variant?: TextVariant;
    color?: TextColor;
    as?: TextElementType;
    className?: string;
    overflowEllipsis?: boolean;
}
