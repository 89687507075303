import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Actions,
    ButtonVariant,
    IconType,
    Menu,
    MenuItemSection,
} from '../../components';
import { EventActionType, EventSource, EventTarget } from '../../core/types';
import { useMap, useNavigation } from '../../hooks';
import { eventManager } from '../../index';

export const DrivingMenu: FC = () => {
    const { clearRoute, stopSimulation } = useNavigation();
    const { resetCamera, toggleBuildings, buildingsVisible } = useMap();
    const history = useHistory();
    const handleExitDriving = () => {
        stopSimulation();
        eventManager.sendEventToIncari({
            type: EventActionType.ToggleDriveMode,
            source: EventSource.Navigation,
            target: EventTarget.Widget,
            payload: false,
        });
        resetCamera();
        clearRoute();
        history.replace('/app');
    };
    const drivingMenu: MenuItemSection[] = [
        {
            items: [
                { icon: IconType.Settings, title: 'Options' },
                { icon: IconType.Volume, title: 'Volume' },
                {
                    icon: IconType.Buildings,
                    title: '3D buildings',
                    onClick: () => toggleBuildings(),
                    isActive: buildingsVisible,
                },
                { icon: IconType.Traffic, title: 'Traffic' },
                { icon: IconType.NorthUp, title: 'North up' },
            ],
        },
    ];
    return (
        <div className="driving-menu">
            <Menu sections={drivingMenu} />
            <Actions
                buttons={[
                    {
                        label: 'Exit',
                        variant: ButtonVariant.Alert,
                        onClick: handleExitDriving,
                    },
                ]}
            />
        </div>
    );
};
