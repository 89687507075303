import { EventAction, EventTarget } from '../core/types';

/**
 * Mock of BRZNativeAPI used by /dev-test
 * Used to simulate communication between two instances of the app (navigation & widget)
 */
export class BRZNativeAPI {
    
    // References to widget and navigation iframes
    private widget: any;
    private navigation: any;

    constructor(
        navigationInstance: HTMLFrameElement,
        widgetInstance: HTMLFrameElement,
    ) {
        this.widget = widgetInstance;
        this.navigation = navigationInstance;
    }

    private getTargetInstance(target: EventTarget): any {
        return {
            [EventTarget.Navigation]: this.navigation,
            [EventTarget.Widget]: this.widget,
        }[target].current!.contentWindow;
    }

    // This is the only method exposed by the API
    // Its only role is to forward any data (string) that it gets to the target iframe
    public HTMLToIncari(data: string): void {
        const eventData = JSON.parse(data);
        this.getTargetInstance((eventData as EventAction).target).sendEvent(
            data,
        );
    }
}
