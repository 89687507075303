/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { MapLayerProps } from '../../core/types';
import { MapContext } from './MapProvider';

export const MapBuildings: FC<MapLayerProps> = ({ visible }) => {
    const { map } = useContext(MapContext);
    const [layerLoaded, setLayerLoaded] = useState(false);
    useEffect(() => {
        if (map && !layerLoaded && visible) {
            map.addLayer({
                id: '3d-buildings',
                source: 'composite',
                'source-layer': 'building',
                filter: ['==', 'extrude', 'true'],
                type: 'fill-extrusion',
                minzoom: 15,
                paint: {
                    'fill-extrusion-color': '#12151a',
                    'fill-extrusion-opacity': 0.6,
                    'fill-extrusion-height': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'height'],
                    ],
                    'fill-extrusion-base': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'min_height'],
                    ],
                },
            });
            setLayerLoaded(true);
        }
    }, [map, visible]);
    useEffect(() => {
        if (map && layerLoaded && !visible) {
            map.removeLayer('3d-buildings');
            setLayerLoaded(false);
        }
    }, [visible]);
    return null;
};
