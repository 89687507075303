import {
    Destination,
    DestinationName,
    Feature,
    SetAddressPresetEventPayload,
} from '../types';

export enum SearchActionType {
    SetQuery = 'SEARCH/SET_QUERY',
    SetResults = 'SEARCH/SET_RESULTS',
    ClearSearchResults = 'SEARCH/CLEAR_RESULTS',
    SetCustomDestination = 'SEARCH/SET_CUSTOM_DESTINATION',
    SelectDestination = 'SEARCH/SELECT_DESTINATION',
    UpdateAddressPreset = 'SEARCH/UPDATE_ADDRESS_PRESET',
}

export interface SetSearchQueryAction {
    type: SearchActionType.SetQuery;
    payload: string;
}

export interface SetSearchResultsAction {
    type: SearchActionType.SetResults;
    payload: Feature[];
}

export interface ClearSearchResultsAction {
    type: SearchActionType.ClearSearchResults;
    payload: Feature[];
}

export interface SetCustomDestinationAction {
    type: SearchActionType.SetCustomDestination;
    payload: Destination;
}

export interface SelectDestinationAction {
    type: SearchActionType.SelectDestination;
    payload: DestinationName;
}

export interface UpdateAddressPresetAction {
    type: SearchActionType.UpdateAddressPreset;
    payload: SetAddressPresetEventPayload;
}

export type SearchAction =
    | SetSearchQueryAction
    | SetSearchResultsAction
    | SetCustomDestinationAction
    | SelectDestinationAction
    | ClearSearchResultsAction
    | UpdateAddressPresetAction;
