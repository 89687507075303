import cx from 'classnames';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { Icon, IconColor, IconSize, IconType } from '../icon';
import { Text, TextColor } from '../typography';

export type ButtonType = 'button' | 'submit' | 'reset';

export enum ButtonVariant {
    Primary = 'primary',
    Secondary = 'secondary',
    Alert = 'alert',
}

export enum ButtonSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

const getTextColorForVariant = (variant: ButtonVariant) =>
    ({
        [ButtonVariant.Primary]: TextColor.Blue,
        [ButtonVariant.Secondary]: TextColor.Gray,
        [ButtonVariant.Alert]: TextColor.Red,
    }[variant]);

export const getButtonClasses = (
    size = ButtonSize.Medium,
    disabled?: boolean,
    dotted?: boolean,
    className?: string,
    variant: ButtonVariant = ButtonVariant.Secondary,
) =>
    cx(
        'button',
        `button--size-${size}`,
        `button--variant-${variant}`,
        {
            'button--disabled': disabled,
            'button--dotted': dotted,
        },
        className,
    );

export const ButtonBase: FC<ButtonBaseProps> = ({
    icon,
    label,
    iconColor = IconColor.Gray,
    iconSize = IconSize.Medium,
    variant = ButtonVariant.Secondary,
    children,
}) => {
    return (
        <>
            {icon && (
                <Icon
                    icon={icon}
                    color={iconColor}
                    size={iconSize}
                    className="button__icon"
                />
            )}
            {label && (
                <Text
                    className="button__label"
                    color={getTextColorForVariant(variant)}
                    text={label}
                />
            )}
            {children}
        </>
    );
};

export interface ButtonBaseProps {
    label?: string;
    onClick?: MouseEventHandler;
    icon?: IconType;
    iconColor?: IconColor;
    iconSize?: IconSize;
    size?: ButtonSize;
    disabled?: boolean;
    busy?: boolean;
    buttonType?: ButtonType;
    className?: string;
    children?: ReactNode;
    hint?: string;
    dotted?: boolean;
    variant?: ButtonVariant;
}
