/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-ignore
import mapboxgl, { Map } from '!mapbox-gl';
import React, {
    createContext,
    memo,
    ReactNode,
    RefObject,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { config } from '../../config';
import { CameraOptions } from '../../core/types';

export interface MapContextType {
    map: Map | null;
    mapContainer: RefObject<HTMLDivElement> | null;
}

export const MapContext = createContext<MapContextType>({
    map: null,
    mapContainer: null,
});

export const MapProvider = memo<MapProviderProps>(({ viewport, children }) => {
    const [map, setMap] = useState<Map | null>(null);
    const mapContainer = useRef(null);
    useLayoutEffect(() => {
        mapboxgl.accessToken = config.mapboxToken;
        const initMap = ({
            setMap,
            mapContainer,
        }: {
            setMap: any;
            mapContainer: any;
        }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: config.mapStyle,
                center: [viewport.lng, viewport.lat],
                zoom: viewport.zoom,
                bearing: viewport.bearing,
                fadeDuration: 0,
                attributionControl: false,
                boxZoom: false,
                dragRotate: false,
                touchZoomRotate: false,
                pitchWithRotate: false,
                scrollZoom: false,
                touchPitch: false,
                keyboard: false,
                renderWorldCopies: false,
            });
            map.on('load', () => {
                setMap(map);
                map.resize();
            });
        };
        if (!map) {
            return initMap({ setMap, mapContainer });
        }
    }, []);
    return (
        <MapContext.Provider value={{ map, mapContainer }}>
            {children}
        </MapContext.Provider>
    );
});

interface MapProviderProps {
    viewport: CameraOptions;
    children?: ReactNode;
}
