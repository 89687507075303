import cx from 'classnames';
import React, { FC } from 'react';
import { Button, ButtonSize, ButtonVariant } from '../button';

export const Actions: FC<ActionsProps> = ({ buttons, className }) => {
    const classes = cx(
        'actions',
        { 'actions--multiple': buttons.length > 1 },
        className,
    );
    return (
        <div className={classes}>
            {buttons &&
                buttons.map((button) => {
                    return !button.hidden ? (
                        <Button
                            key={`action-button-${button.label}`}
                            size={ButtonSize.Large}
                            className="actions__action"
                            {...button}
                        />
                    ) : null;
                })}
        </div>
    );
};

export interface ActionButton {
    label: string;
    onClick?: () => void;
    variant?: ButtonVariant;
    hidden?: boolean;
}

interface ActionsProps {
    className?: string;
    buttons: ActionButton[];
}
