import cx from 'classnames';
import React, { FC, useState } from 'react';
import { isSpecialKey, KeyboardKey, KeyboardKeyType } from './KeyboardKey';
import {
    keyboardLayout,
    KeyboardLayoutRow,
    KeyboardSpecialKey,
    SpecialKeyType,
} from './KeyboardLayout';

const isLayoutToggleKey = (char: KeyboardSpecialKey): boolean =>
    char.type === SpecialKeyType.LayoutSwitch;

const isEnterKey = (char: KeyboardSpecialKey): boolean =>
    char.type === SpecialKeyType.Enter;

export const Keyboard: FC<KeyboardProps> = ({
    onKeyPress,
    onEnter,
    className,
}) => {
    const keyboardClass = cx('keyboard', className);
    const [secondaryLayout, toggleLayout] = useState(false);
    const handleKeyPress = (char: KeyboardKeyType) => {
        if (
            isSpecialKey(char) &&
            isLayoutToggleKey(char as KeyboardSpecialKey)
        ) {
            toggleLayout(!secondaryLayout);
        } else if (
            isSpecialKey(char) &&
            isEnterKey(char as KeyboardSpecialKey)
        ) {
            if (onEnter) {
                onEnter();
            }
        } else if (onKeyPress) {
            onKeyPress(char);
        }
    };
    const charIndex = secondaryLayout ? 1 : 0;
    const getSpecialKeyClasses = (char: KeyboardSpecialKey) => {
        return cx(`keyboard-key--special-${char.type.toLowerCase()}`, {
            'keyboard-key--pressed': isLayoutToggleKey(char) && secondaryLayout,
        });
    };
    return (
        <div className={keyboardClass}>
            {keyboardLayout.map((row: KeyboardLayoutRow, index: number) => {
                const { specialLeft, specialRight, characters } = row;
                return (
                    <div
                        className="keyboard__row"
                        key={`keyboard-row-${index}`}
                    >
                        {specialLeft &&
                            specialLeft.map((char: KeyboardSpecialKey) => {
                                return (
                                    <KeyboardKey
                                        char={char}
                                        key={`special-key-${char.type}`}
                                        onClick={handleKeyPress}
                                        className={getSpecialKeyClasses(char)}
                                    />
                                );
                            })}
                        {characters &&
                            Array.from(characters[charIndex]).map(
                                (char: string) => {
                                    return (
                                        <KeyboardKey
                                            char={char}
                                            key={char}
                                            onClick={handleKeyPress}
                                            className={`keyboard-key--${char}`}
                                        />
                                    );
                                },
                            )}
                        {specialRight &&
                            specialRight.map((char: KeyboardSpecialKey) => {
                                return (
                                    <KeyboardKey
                                        char={char}
                                        key={`special-key-${char.type}`}
                                        onClick={handleKeyPress}
                                        className={getSpecialKeyClasses(char)}
                                    />
                                );
                            })}
                    </div>
                );
            })}
        </div>
    );
};

interface KeyboardProps {
    onKeyPress: (char: KeyboardKeyType) => void;
    onEnter?: () => void;
    className?: string;
}
