import React, { FC } from 'react';
import { IconType, Menu } from '../../components';

export const MainMenu: FC = () => {
    return (
        <Menu
            className="app-menu"
            sections={[
                {
                    title: 'Favorites',
                    items: [
                        {
                            icon: IconType.Home,
                            title: 'Home',
                            link: '/app/route/home',
                        },
                        {
                            icon: IconType.Work,
                            title: 'Work',
                            link: '/app/route/work',
                        },
                        { icon: IconType.Add, title: 'Add' },
                    ],
                },
                {
                    title: 'Other',
                    items: [
                        {
                            icon: IconType.Search,
                            title: 'Search',
                            link: '/app/search',
                        },
                        { icon: IconType.Recent, title: 'Recent' },
                        {
                            icon: IconType.Settings,
                            title: 'Settings',
                        },
                    ],
                },
            ]}
        />
    );
};
