import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { IconColor, IconType } from '../icon';
import { Button } from './Button';
import { ButtonVariant } from './ButtonBase';

export const BackButton: FC<BackButtonProps> = ({
    label = 'Back',
    onClick,
}) => {
    const history = useHistory();
    const handleClick = () => {
        history.goBack();
        if (onClick) {
            onClick();
        }
    };
    return (
        <Button
            className="back-button"
            label={label}
            icon={IconType.Back}
            iconColor={IconColor.Blue}
            onClick={handleClick}
            variant={ButtonVariant.Primary}
        />
    );
};

interface BackButtonProps {
    to?: string;
    label?: string;
    onClick?: () => void;
    closePanelOnClick?: boolean;
}
