import { useDispatch, useSelector } from 'react-redux';
import {
    clearRoute,
    getDirections,
    getSimplePresetDirections,
    setRouteOverview,
    startSimulation,
    stopSimulation,
    toggleRoute,
} from '../core/actions';
import { navigationSelectors } from '../core/selectors';
import { Destination } from '../core/types';
import { AppState } from '../reducer';

export const useNavigation = () => {
    const dispatch = useDispatch();
    const {
        routes,
        selectedRouteId,
        error,
        routeProgress,
        guidance,
        simulationRunning,
        showRouteOverview,
    } = useSelector((state: AppState) => state.navigation);
    const currentRoute = useSelector(navigationSelectors.currentRoute);
    const totalRoutes = useSelector(navigationSelectors.totalRoutes);
    return {
        toggleRoute: () => dispatch(toggleRoute()),
        clearRoute: () => dispatch(clearRoute()),
        getDirections: (destination: Destination) =>
            dispatch(getDirections(destination)),
        getSimplePresetDirections: () => dispatch(getSimplePresetDirections()),
        startSimulation: () => dispatch(startSimulation()),
        stopSimulation: () => dispatch(stopSimulation()),
        setRouteOverview: (show: boolean) => dispatch(setRouteOverview(show)),
        error,
        routes,
        selectedRouteId,
        routeProgress,
        guidance,
        simulationRunning,
        currentRoute,
        totalRoutes,
        showRouteOverview,
    };
};
