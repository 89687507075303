import { config } from '../../config';
import { MapAction, MapActionType, PositionMarkerOptions } from '../actions';
import { CameraOptions } from '../types';
export interface MapState extends CameraOptions {
    backgroundMode: boolean;
    position: PositionMarkerOptions;
    showBuildings: boolean;
    freeMode: boolean; // Free interactions (camera not centered)
    mapViewport: CameraOptions; // Read-only current map viewport (for other actions' reference)
    updateHash: number;
}

const DEFAULT_ZOOM = 15;
const CAMERA_ANGLE_CORRECTION = 90;

const cameraViewportDefaults = {
    zoom: DEFAULT_ZOOM,
    bearing: 0,
    pitch: 0,
    offset: [0, 0],
};

const initialState: MapState = {
    backgroundMode: false,
    position: config.initialPosition,
    showBuildings: false,
    freeMode: false,
    ...cameraViewportDefaults,
    ...config.initialPosition,
    mapViewport: {
        ...config.initialPosition,
        ...cameraViewportDefaults,
    },
    updateHash: -1,
};

export const mapReducer = (
    state: MapState = initialState,
    action: MapAction,
): MapState => {
    switch (action.type) {
        case MapActionType.SetZoom: {
            return {
                ...state,
                ...state.mapViewport, // Get current read-only map viewport...
                zoom: action.payload,
            };
        }
        case MapActionType.SetCamera: {
            const { bearing, pitch, zoom, offset, lat, lng } = action.payload;
            return {
                ...state,
                lat,
                lng,
                bearing: bearing + CAMERA_ANGLE_CORRECTION,
                pitch,
                zoom,
                offset,
            };
        }
        case MapActionType.ResetCamera: {
            const { lat, lng } = state.position;
            return {
                ...state,
                ...cameraViewportDefaults,
                freeMode: false,
                lat,
                lng,
                updateHash: Math.random(),
            };
        }
        case MapActionType.SetCurrentPosition: {
            const { lat, lng, rotation } = action.payload;
            return {
                ...state,
                position: { lat, lng, rotation },
            };
        }
        case MapActionType.UpdateViewport: {
            return {
                ...state,
                mapViewport: action.payload,
            };
        }
        case MapActionType.ToggleBuildings: {
            return {
                ...state,
                showBuildings: !state.showBuildings,
            };
        }
        case MapActionType.ToggleBackgroundMode: {
            return {
                ...state,
                backgroundMode: !state.backgroundMode,
            };
        }
        case MapActionType.SetFreeMode: {
            return {
                ...state,
                freeMode: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default mapReducer;
