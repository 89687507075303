import cx from 'classnames';
import React, { FC } from 'react';
import { Text, TextColor, TextVariant } from './Text';

export const Counter: FC<CounterProps> = ({ value, label, className }) => {
    const classes = cx('counter', className);
    return (
        <div className={classes}>
            <Text
                className="counter__value"
                variant={TextVariant.LabelMedium}
                color={TextColor.White}
                text={value}
            />
            <Text
                className="counter__label"
                variant={TextVariant.BodySmall}
                text={label}
            />
        </div>
    );
};

interface CounterProps {
    value: string;
    label: string;
    className?: string;
}
