import { Coordinates } from './Coordinates';
import { DestinationDetails, DestinationName } from './Destination';
import { RouteGuidance } from './RouteGuidance';

export interface UpdatePositionEventPayload {
    lat: number;
    lng: number;
    bearing: number;
}

export interface ShowRouteEventPayload {
    preset: DestinationName;
}

export interface UpdateDestinationDetailsEventPayload {
    [DestinationName.Home]: DestinationDetails;
    [DestinationName.Work]: DestinationDetails;
}

export interface UpdateTurnByTurnGuidanceEventPayload {
    guidance: RouteGuidance;
}

export interface SetAddressPresetEventPayload {
    preset: DestinationName;
    address1: string;
    address2: string;
    coordinates: Coordinates;
}
