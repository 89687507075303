import React, { FC } from 'react';
import { Text, TextColor, TextVariant } from '../../components';
import { RouteGuidance } from '../../core/types';
import { formatDistance } from '../../utils';
import { Maneuver } from './Maneuver';

export const Guidance: FC<GuidanceProps> = ({ data }) => {
    const { distanceToNextStep, drivingSide, instructions } = data;
    const {
        primary: { text, modifier, type },
    } = instructions;
    return (
        <div className="guidance">
            <figure className="guidance__pictogram">
                <Maneuver
                    modifier={modifier}
                    type={type}
                    drivingSide={drivingSide}
                />
            </figure>
            <div className="guidance__hints">
                <Text
                    color={TextColor.White}
                    variant={TextVariant.LabelXLarge}
                    text={formatDistance(distanceToNextStep)}
                />
                <Text text={text} />
            </div>
        </div>
    );
};

interface GuidanceProps {
    data: RouteGuidance;
}
