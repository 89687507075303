import { createSelector } from 'reselect';
import { AppState } from '../../reducer';

const routes = (state: AppState) => state.navigation.routes;

const selectedRouteId = (state: AppState) => state.navigation.selectedRouteId;

const currentRoute = createSelector(
    routes,
    selectedRouteId,
    (routes, selectedId) => routes[selectedId] || null,
);

const totalRoutes = createSelector(routes, (r) => r.length);

const routeProgress = (state: AppState) => state.navigation.routeProgress;

const error = (state: AppState) => state.navigation.error;

export const navigationSelectors = {
    error,
    routes,
    selectedRouteId,
    routeProgress,
    totalRoutes,
    currentRoute,
};
