import React, { FC } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { RouteSummary } from './components';

export const Routes: FC = () => {
    const { path } = useRouteMatch();
    return (
        <div className="routes">
            <Route path={`${path}/:name`}>
                <RouteSummary />
            </Route>
        </div>
    );
};
