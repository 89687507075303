/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BackButton, Message } from '../../components';
import {
    Destination,
    DestinationName,
    EventActionType,
    EventSource,
    EventTarget,
} from '../../core/types';
import { useMap, useNavigation, useSearch } from '../../hooks';
import { eventManager } from '../../index';
import { RouteSummaryDetails } from './RouteSummaryDetails';

export const RouteSummary: FC = () => {
    const history = useHistory();
    const { name } = useParams<{ name?: string }>();
    const {
        getDirections,
        toggleRoute,
        startSimulation,
        clearRoute,
        setRouteOverview,
        totalRoutes,
        simulationRunning,
        error,
    } = useNavigation();
    const { resetCamera, setFreeMode } = useMap();
    const { destination, selectDestination } = useSearch();
    useEffect(() => {
        setRouteOverview(true);
    }, []);
    useEffect(() => {
        if (name) {
            selectDestination(name as DestinationName);
        }
    }, [name]);
    useEffect(() => {
        if (destination) {
            getDirections(destination as Destination);
        }
    }, [destination]);
    const handleToggleRoute = () => {
        toggleRoute();
    };
    const handleBack = () => {
        if (simulationRunning) {
            handleContinueDriving();
        } else {
            clearRoute();
            resetCamera();
            history.push('/app/menu');
        }
    };
    const handleStart = () => {
        startSimulation();
        handleContinueDriving();
        eventManager.sendEventToIncari({
            type: EventActionType.ToggleDriveMode,
            source: EventSource.Navigation,
            target: EventTarget.Widget,
            payload: true,
        });
    };
    const handleContinueDriving = () => {
        setFreeMode(false);
        setRouteOverview(false);
        history.push('/app/drive');
    };
    return destination ? (
        <div className="route-summary">
            <BackButton onClick={handleBack} />
            {error ? (
                <Message
                    className="route-summary__not-found"
                    title="Cannot find route"
                    subtitle="The selected destination is currently unreachable"
                />
            ) : (
                <RouteSummaryDetails
                    onStart={handleStart}
                    onContinue={handleContinueDriving}
                    onChangeRoute={handleToggleRoute}
                    routesCount={totalRoutes}
                    address1={destination.address1}
                    address2={destination.address2}
                    simulationRunning={simulationRunning}
                />
            )}
        </div>
    ) : null;
};
