import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { App } from './app';
import { EventManager } from './core/EventManager';
import { DevTest } from './dev-test/DevTest';
import './index.scss';
import { store } from './store';

export const history = createBrowserHistory();

export const eventManager = new EventManager(window, store.dispatch);
eventManager.registerEventListener();

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Switch>
                <Route exact path="/dev-test">
                    <DevTest />
                </Route>
                <Route path="*">
                    <Provider store={store}>
                        <App />
                    </Provider>
                </Route>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);
