import cx from 'classnames';
import React, { FC } from 'react';
import { Text, TextVariant } from '../typography';
import { MenuItem } from './Menu';
import { MenuButton } from './MenuButton';

export const MenuSection: FC<MenuSectionProps> = ({
    title,
    items,
    className,
}) => {
    const sectionClass = cx('menu-section', className);
    return (
        <div className={sectionClass}>
            {title && (
                <Text
                    className="menu-section__title"
                    variant={TextVariant.BodyXSmall}
                    text={title}
                />
            )}
            <div className="menu-section__items">
                {items.map((item, index) => {
                    return (
                        <MenuButton
                            item={item}
                            key={`menu-${title}-${index}`}
                        />
                    );
                })}
            </div>
        </div>
    );
};

interface MenuSectionProps {
    className?: string;
    title?: string;
    items: MenuItem[];
}
