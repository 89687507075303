import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import {
    Button,
    Icon,
    IconColor,
    IconSize,
    IconType,
    Text,
    TextColor,
    TextVariant,
} from '../../components';
import { EventActionType, EventSource, EventTarget } from '../../core/types';
import { eventManager } from '../../index';

export const WidgetLayout: FC<WidgetLayoutProps> = ({
    children,
    className,
    uiComparison = false,
}) => {
    const handleSearch = () => {
        eventManager.sendEventToIncari({
            source: EventSource.Widget,
            target: EventTarget.Navigation,
            type: EventActionType.OpenSearch,
        });
    };
    const classes = cx('widget-layout', className, {
        'widget-layout--ui-comparison': uiComparison,
    });
    return (
        <div className={classes}>
            <Text
                text="Navigation"
                variant={TextVariant.LabelSmall}
                color={TextColor.Gold}
                className="widget-layout__title"
            />
            <main className="widget-layout__content">{children}</main>
            <div className="widget-layout__actions">
                <Icon
                    className="widget-layout__action"
                    icon={IconType.Recent}
                    size={IconSize.XLarge}
                    color={IconColor.Blue}
                />
                <Button
                    className="widget-layout__action"
                    icon={IconType.Search}
                    iconSize={IconSize.XLarge}
                    iconColor={IconColor.Blue}
                    onClick={handleSearch}
                />
            </div>
        </div>
    );
};

interface WidgetLayoutProps {
    className?: string;
    children: ReactNode;
    uiComparison?: boolean;
}
