import {
    add,
    addSeconds,
    format as formatFns,
    formatDistanceStrict as formatDistanceFns,
} from 'date-fns';
import format from 'format-duration';

export function splitAt(str: string, index: number): string[] {
    return [str.slice(0, index), str.slice(index)];
}

export function formatDuration(
    seconds: number = 0,
    formatInWords: boolean = false,
): string {
    const now = new Date();
    const changeUnits = (s: string) =>
        s.replace('minutes', 'min').replace('hours', 'hr').replace('days', 'd');
    return formatInWords
        ? changeUnits(formatDistanceFns(now, add(now, { seconds })))
        : format(seconds * 1000);
}

export function roundDistance(
    num: number,
    round: boolean,
    nearest: number = 10,
) {
    return round ? Math.round(num / nearest) * nearest : num;
}

export const formatDistance = (
    distanceInMeters: number = 0,
    round: boolean = true,
): string => {
    const m = distanceInMeters;
    if (m < 1000) {
        const unit = `<span class="unit">m</span>`;
        if (m < 100) {
            return `${roundDistance(m, round).toFixed()} ${unit}`;
        }
        return `${roundDistance(m, round, 50).toFixed()} ${unit}`;
    } else if (m < 100000) {
        const unit = `<span class="unit">km</span>`;
        return `${(m / 1000).toFixed(1).replace('.', ',')} ${unit}`;
    }
    return `${roundDistance(
        m / 1000,
        round,
        100,
    ).toFixed()} <span class="unit">km</span>`;
};

export const formatTimeLeft = (durationInSeconds: number = 0): string => {
    return formatDuration(durationInSeconds);
};

export const formatArrival = (durationInSeconds: number = 0): string => {
    const arrivalTime = addSeconds(Date.now(), durationInSeconds);
    return formatFns(arrivalTime, 'HH:mm');
};
