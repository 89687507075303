import { config } from '../../config';

export enum RequestMethod {
    Get = 'GET',
    Post = 'POST',
}

export interface RequestResult {
    success: boolean;
    code?: number;
    data: any;
}

export class MapBoxApiService {
    protected apiUrl = 'https://api.mapbox.com';
    protected serviceUrl = '';
    private accessToken = config.mapboxToken;

    private buildApiUrl = (params: string) => {
        const baseUrl = `${this.apiUrl}/${this.serviceUrl}`;
        const uri = params !== '' ? `${baseUrl}${params}` : baseUrl;
        const accessTokenParam = uri.includes('?')
            ? '&access_token='
            : '?access_token=';
        return encodeURI(`${uri}${accessTokenParam}${this.accessToken}`);
    };

    private getRequestOptions = async (method: RequestMethod, data: any) => {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method,
            ...(data && { body: JSON.stringify(data) }),
        };
    };

    private async fetchToJson<BodyType = any>(
        method: RequestMethod,
        params: string,
        data?: BodyType,
    ): Promise<RequestResult> {
        try {
            const result: Response = await fetch(
                this.buildApiUrl(params),
                await this.getRequestOptions(method, data),
            );
            const { ok, status, statusText } = result;
            return {
                success: ok,
                code: status,
                data: ok ? await result.json() : (statusText as string),
            };
        } catch (e) {
            console.error('[FETCH ERROR]', e);
            return { success: false, data: e.message || e };
        }
    }

    public get(params: string = '') {
        return this.fetchToJson(RequestMethod.Get, params);
    }

    public post<T>(params: string = '', data: T) {
        return this.fetchToJson<T>(RequestMethod.Post, params, data);
    }
}
