import { AppState } from '../../reducer';

const zoom = (state: AppState) => state.map.mapViewport.zoom;
const position = (state: AppState) => state.map.position;
const mapViewport = (state: AppState) => state.map.mapViewport;
const buildingsVisible = (state: AppState) => state.map.showBuildings;
const freeMode = (state: AppState) => state.map.freeMode;

export const mapSelectors = {
    zoom,
    position,
    mapViewport,
    buildingsVisible,
    freeMode,
};
