import { along, bearing, bearingToAngle, lineString } from '@turf/turf';
import { LineString } from 'geojson';
import { Route, RouteGuidance, RouteStep } from './types';

class Simulator {
    private route: Route | null = null;
    private speedMps: number = 0;
    private stepIndex: number = 0;
    private interval: number = 0; // ms
    private timeElapsed: number = 0;
    private stepDistanceTravelled: number = 0;
    private distanceTravelled: number = 0;
    private tickCount: number = 0;
    private timeout: NodeJS.Timeout | null = null;
    private stepDistanceRanges: { from: number; to: number }[] = [];
    private reachedDestination: boolean = false;

    public init(
        route: Route,
        speedKmh: number = 50,
        refreshInterval: number = 100,
        refreshCallback: () => void,
    ) {
        this.route = route;
        this.speedMps = speedKmh / 3.6;
        this.interval = refreshInterval;
        this.timeout = setInterval(refreshCallback, this.interval);
        this.createStepRangesMap();
    }

    public reset() {
        this.stepIndex = 0;
        this.tickCount = 0;
        this.timeElapsed = 0; // seconds
        this.stepDistanceTravelled = 0;
        this.distanceTravelled = 0; // meters
        this.reachedDestination = false;
        clearInterval(this.timeout as NodeJS.Timeout);
    }

    private get steps(): RouteStep[] {
        return this.route && this.route.legs ? this.route?.legs[0].steps : [];
    }

    private get currentStep(): RouteStep | null {
        const index = this.stepDistanceRanges.findIndex(
            (range) =>
                this.distanceTravelled >= range.from &&
                this.distanceTravelled <= range.to,
        );
        if (index === -1) {
            this.reachedDestination = true;
        } else {
            this.stepIndex = index;
        }
        return this.steps.length > 0 && this.steps[this.stepIndex]
            ? this.steps[this.stepIndex]
            : null;
    }

    private createStepRangesMap() {
        this.stepDistanceRanges = [];
        this.steps.reduce((totalDistance: number, step: RouteStep) => {
            const dist = totalDistance + step.distance;
            this.stepDistanceRanges.push({
                from: totalDistance,
                to: dist,
            });
            return dist;
        }, 0);
    }

    private getGuidance(): RouteGuidance | null {
        if (!this.currentStep) {
            return null;
        }
        const {
            distance,
            bannerInstructions,
            maneuver,
            driving_side: drivingSide,
        } = this.currentStep;
        return {
            distanceToNextStep: distance - this.stepDistanceTravelled,
            instructions: bannerInstructions[0],
            maneuver,
            drivingSide,
        };
    }

    private getCoordsAtDistance(distance: number) {
        const {
            geometry: {
                coordinates: [lng, lat],
            },
        } = along(
            lineString((this.route!.geometry as LineString).coordinates),
            distance,
            {
                units: 'meters',
            },
        );
        return [lat, lng];
    }

    public tick() {
        if (!this.route || this.reachedDestination) {
            return;
        }
        const curPoint = this.getCoordsAtDistance(this.distanceTravelled);
        this.tickCount++;
        this.distanceTravelled =
            (this.tickCount * (this.speedMps * this.interval)) / 1000;
        this.timeElapsed += this.interval / 1000;
        this.stepDistanceTravelled =
            this.distanceTravelled -
            this.stepDistanceRanges[this.stepIndex].from;
        const nextPoint = this.getCoordsAtDistance(this.distanceTravelled);
        const cameraBearing = -bearing(curPoint, nextPoint);
        return {
            lat: curPoint[0],
            lng: curPoint[1],
            rotate: bearingToAngle(cameraBearing),
            bearing: cameraBearing,
            currentStep: this.stepIndex,
            timeElapsed: this.timeElapsed,
            distanceTravelled: this.distanceTravelled,
            guidance: this.getGuidance(),
            updateCamera: this.tickCount % 10 === 0, // update camera position only every 10 ticks
            reachedDestination: this.reachedDestination,
        };
    }
}

export const simulator = new Simulator();
