import React, { FC } from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { Main, Widget } from './components';
import { useNetworkConnection } from '../hooks';
import { Offline } from './components/Offline';

export const App: FC = () => {
    const online = useNetworkConnection();
    const classes = cx('app', { 'app--offline': !online });
    return (
        <div className={classes}>
            <Switch>
                <Route path="/widget">
                    <Widget className="app__widget" />
                </Route>
                <Route path="/app">
                    <Main />
                    {!online && <Offline /> }
                </Route>
            </Switch>        
        </div>
    );
};
