import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducer';
import { mapSelectors } from '../selectors';
import { CameraOptions } from '../types';
import { MapActionType, PositionMarkerOptions } from './mapActionTypes';

export const setZoom = (zoom: number) => ({
    type: MapActionType.SetZoom,
    payload: zoom,
});

export const setCurrentPosition = (options: PositionMarkerOptions) => ({
    type: MapActionType.SetCurrentPosition,
    payload: options,
});

export const updateMapViewport = (viewport: CameraOptions) => ({
    type: MapActionType.UpdateViewport,
    payload: viewport,
});

const setCamera = (camera: CameraOptions) => ({
    type: MapActionType.SetCamera,
    payload: camera,
});

export const resetCamera = () => ({
    type: MapActionType.ResetCamera,
});

export const toggleBuildings = () => ({
    type: MapActionType.ToggleBuildings,
});

export const toggleBackgroundMode = () => ({
    type: MapActionType.ToggleBackgroundMode,
});

export const setFreeMode = (mode: boolean) => ({
    type: MapActionType.SetFreeMode,
    payload: mode,
});

export const moveCamera = (
    camera: CameraOptions,
): ThunkAction<void, AppState, unknown, Action> => (dispatch, getState) => {
    const freeMode = mapSelectors.freeMode(getState());
    if (!freeMode) {
        dispatch(setCamera(camera));
    }
};

export const zoomIn = (): ThunkAction<void, AppState, unknown, Action> => (
    dispatch,
    getState,
) => {
    const currentZoom = mapSelectors.zoom(getState());
    dispatch(setZoom(currentZoom + 1));
};

export const zoomOut = (): ThunkAction<void, AppState, unknown, Action> => (
    dispatch,
    getState,
) => {
    const currentZoom = mapSelectors.zoom(getState());
    dispatch(setZoom(currentZoom - 1));
};
