import cx from 'classnames';
import React, { FC } from 'react';
import { getIcon, IconType } from './IconsMapping';

export enum IconColor {
    Gray = 'gray',
    White = 'white',
    Blue = 'blue',
    Black = 'black',
    Original = 'original',
}

export enum IconSize {
    XSmall = 'xs',
    Small = 's',
    Medium = 'm',
    Large = 'l',
    XLarge = 'xl',
    XXLarge = 'xxl',
    XXXLarge = 'xxxl',
}

export const Icon: FC<IconProps> = ({
    icon,
    color = IconColor.Gray,
    size = IconSize.Medium,
    className,
}) => {
    const iconClass = cx(
        'icon',
        `icon--color-${color}`,
        `icon--size-${size}`,
        className,
    );
    const RenderedIcon = getIcon(icon);
    return (
        <div className={iconClass}>
            <RenderedIcon />
        </div>
    );
};

export interface IconProps {
    icon: IconType;
    color?: IconColor;
    size?: IconSize;
    className?: string;
}
