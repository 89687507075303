import { toGeoJSON } from '@mapbox/polyline';
import { Coordinates, Route } from '../types';
import { Waypoint } from '../types/Waypoint';
import { MapBoxApiService } from './MapBoxApiService';

export enum RoutingProfile {
    DrivingTraffic = 'mapbox/driving-traffic',
    Driving = 'mapbox/driving',
    Walking = 'mapbox/walking',
    Cycling = 'mapbox/cycling',
}

interface DirectionsApiResponse {
    code: 'Ok' | 'NoRoute' | 'NoSegment' | 'ProfileNotFound' | 'InvalidInput';
    routes: Route[];
    uuid: string;
    waypoints: Waypoint[];
}

class DirectionsService extends MapBoxApiService {
    protected serviceUrl = 'directions/v5';

    private coordinatesToUrl(coordinates: Coordinates[]): string {
        return coordinates.map(({ lat, lng }) => `${lng},${lat}`).join(';');
    }

    public async getDirections(
        from: Coordinates,
        to: Coordinates,
        summaryOnly: boolean = false,
    ): Promise<Route[] | boolean> {
        const result = (await this.fetchDirections(
            [from, to],
            RoutingProfile.DrivingTraffic,
            summaryOnly,
        )) as DirectionsApiResponse;
        if (result && result.code === 'Ok') {
            return this.parseRoutes(result.routes);
        } else if (result && result.code) {
            return false;
        }
        return [];
    }

    private async fetchDirections(
        coordinates: Coordinates[],
        routingProfile: RoutingProfile = RoutingProfile.DrivingTraffic,
        summaryOnly?: boolean,
    ): Promise<DirectionsApiResponse | boolean> {
        try {
            const params = summaryOnly
                ? ''
                : '?steps=true&alternatives=true&overview=full&annotations=maxspeed&language=en&banner_instructions=true';
            const result = await this.get(
                `/${routingProfile}/${this.coordinatesToUrl(
                    coordinates,
                )}${params}`,
            );
            return result.success ? result.data : false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private parseRoutes(routes: Route[]) {
        return routes.map(({ geometry, ...rest }) => {
            return {
                geometry: toGeoJSON(geometry as string),
                ...rest,
            };
        });
    }
}

export const directionsService = new DirectionsService();
