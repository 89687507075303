import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { eventManager } from '../..';
import { AppState } from '../../reducer';
import {
    mapSelectors,
    navigationSelectors,
    searchSelectors,
} from '../selectors';
import { directionsService } from '../services';
import { simulator } from '../Simulator';
import {
    Destination,
    DestinationName,
    EventActionType,
    EventSource,
    EventTarget,
    Route,
    RouteGuidance,
    RouteProgress,
    UpdateDestinationDetailsEventPayload,
    UpdateTurnByTurnGuidanceEventPayload,
} from '../types';
import { moveCamera, setCurrentPosition } from './mapActions';
import { NavigationActionType } from './navigationActionTypes';

const setRoutes = (routes: Route[]) => ({
    type: NavigationActionType.SetRoutes,
    payload: routes,
});

export const clearRoute = () => ({
    type: NavigationActionType.ClearRoute,
});

const setCurrentRoute = (routeId: number) => ({
    type: NavigationActionType.ToggleRoute,
    payload: routeId,
});

const setSimulationStarted = () => ({
    type: NavigationActionType.StartSimulation,
});

const setSimulationStopped = () => ({
    type: NavigationActionType.StopSimulation,
});

const toggleNavigationError = (c: boolean) => ({
    type: NavigationActionType.ToggleError,
    payload: toggleNavigationError,
});

const updateRouteProgress = (progress: RouteProgress | null) => ({
    type: NavigationActionType.UpdateRouteProgress,
    payload: progress,
});

const setRouteGuidance = (guidance: RouteGuidance | null) => ({
    type: NavigationActionType.UpdateGuidance,
    payload: guidance,
});

const updateGuidance = (guidance: RouteGuidance | null) => (
    dispatch: Dispatch,
) => {
    eventManager.sendEventToIncari({
        type: EventActionType.UpdateTurnByTurnGuidance,
        source: EventSource.Navigation,
        target: EventTarget.Widget,
        payload: {
            guidance,
        } as UpdateTurnByTurnGuidanceEventPayload,
    });
    dispatch(setRouteGuidance(guidance));
};

export const startSimulation = (): ThunkAction<
    void,
    AppState,
    unknown,
    Action
> => (dispatch: Dispatch, getState) => {
    const route = navigationSelectors.currentRoute(getState());
    const SPEED = 50; // kmh
    const REFRESH_INTERVAL = 60; // ms
    const CAMERA_ZOOM = 17;
    const CAMERA_PITCH = 50;
    const CAMERA_Y_OFFSET = 200;
    simulator.init(route, SPEED, REFRESH_INTERVAL, function () {
        const next = simulator.tick();
        if (next) {
            const {
                lat,
                lng,
                rotate,
                bearing,
                updateCamera,
                currentStep,
                timeElapsed,
                distanceTravelled,
                guidance,
            } = next;
            dispatch(setCurrentPosition({ lat, lng, rotation: rotate }));
            if (updateCamera) {
                dispatch(
                    updateRouteProgress({
                        currentStep,
                        timeElapsed,
                        distanceTravelled,
                    }),
                );
                dispatch(updateGuidance(guidance) as any);
                dispatch(
                    moveCamera({
                        lat,
                        lng,
                        bearing,
                        zoom: CAMERA_ZOOM,
                        pitch: CAMERA_PITCH,
                        offset: [0, CAMERA_Y_OFFSET],
                    }) as any,
                );
            }
        }
    });
    dispatch(setSimulationStarted());
};

export const stopSimulation = (): ThunkAction<
    void,
    AppState,
    unknown,
    Action
> => (dispatch: Dispatch) => {
    simulator.reset();
    dispatch(setSimulationStopped());
};

export const toggleRoute = (): ThunkAction<void, AppState, unknown, Action> => (
    dispatch: Dispatch,
    getState,
) => {
    const state = getState();
    const totalRoutes = navigationSelectors.totalRoutes(state);
    const selectedRoute = navigationSelectors.selectedRouteId(state);
    dispatch(
        setCurrentRoute(
            selectedRoute < totalRoutes - 1 ? selectedRoute + 1 : 0,
        ),
    );
};

export const getSimplePresetDirections = (): ThunkAction<
    void,
    AppState,
    unknown,
    Action
> => async (dispatch: Dispatch, getState) => {
    try {
        const state = getState();
        const getDestination = searchSelectors.destinationByName(state);
        const homeDestination = getDestination(DestinationName.Home);
        const workDestination = getDestination(DestinationName.Work);
        try {
            const homeDirections = await directionsService.getDirections(
                mapSelectors.position(state),
                homeDestination!.coordinates,
                true,
            );
            const workDirections = await directionsService.getDirections(
                mapSelectors.position(state),
                workDestination!.coordinates,
                true,
            );
            if (homeDirections && workDirections) {
                const [
                    { distance: hds, duration: hdt },
                ] = homeDirections as Route[];
                const [
                    { distance: wds, duration: wdt },
                ] = workDirections as Route[];
                eventManager.sendEventToIncari({
                    type: EventActionType.UpdateDestinationRoutes,
                    source: EventSource.Navigation,
                    target: EventTarget.Widget,
                    payload: {
                        [DestinationName.Home]: {
                            distance: hds,
                            duration: hdt,
                        },
                        [DestinationName.Work]: {
                            distance: wds,
                            duration: wdt,
                        },
                    } as UpdateDestinationDetailsEventPayload,
                });
            }
        } catch (e) {
            toggleNavigationError(true);
            console.error('Cannot get directions', e);
        }
    } catch (e) {
        console.error('Cannot get directions', e);
    }
};

export const getDirections = (
    destination: Destination,
): ThunkAction<void, AppState, unknown, Action> => async (
    dispatch: Dispatch,
    getState,
) => {
    try {
        const routes = await directionsService.getDirections(
            mapSelectors.position(getState()),
            destination.coordinates,
        );
        if (routes) {
            dispatch(setRoutes(routes as Route[]));
        } else {
            dispatch(toggleNavigationError(true));
        }
    } catch (e) {
        console.error('Cannot get directions');
    }
};

export const setRouteOverview = (show: boolean) => ({
    type: NavigationActionType.SetRouteOverview,
    payload: show,
});
