import { useDispatch, useSelector } from 'react-redux';
import {
    clearSearchResults,
    search,
    selectDestination,
    setFeatureAsCustomDestination,
} from '../core/actions';
import { searchSelectors } from '../core/selectors';
import { DestinationName, Feature } from '../core/types';
import { AppState } from '../reducer';

export const useSearch = () => {
    const dispatch = useDispatch();
    const { query, results, destinations } = useSelector(
        (state: AppState) => state.search,
    );
    const destination = useSelector(searchSelectors.currentDestination);
    return {
        search: (query: string) => dispatch(search(query)),
        setCustomDestination: (place: Feature) =>
            dispatch(setFeatureAsCustomDestination(place)),
        selectDestination: (name: DestinationName) =>
            dispatch(selectDestination(name)),
        clearResults: () => dispatch(clearSearchResults()),
        destination,
        destinations,
        query,
        results,
    };
};
