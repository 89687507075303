import { useDispatch, useSelector } from 'react-redux';
import { toggleWidgetDriveMode } from '../core/actions';
import { AppState } from '../reducer';

export const useWidget = () => {
    const dispatch = useDispatch();
    const {
        destinationDetails,
        guidance,
        driveMode,
        devUIComparison,
    } = useSelector((state: AppState) => state.widget);
    return {
        toggleDriveMode: (isOn: boolean) =>
            dispatch(toggleWidgetDriveMode(isOn)),
        devUIComparison,
        driveMode,
        destinationDetails,
        guidance,
    };
};
