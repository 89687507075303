import { WidgetAction, WidgetActionType } from '../actions';
import { DestinationDetails, DestinationName, RouteGuidance } from '../types';

export interface WidgetState {
    driveMode: boolean;
    guidance: RouteGuidance | null;
    destinationDetails: {
        [DestinationName.Home]: DestinationDetails | null;
        [DestinationName.Work]: DestinationDetails | null;
    };
    devUIComparison: boolean;
}

const initialState: WidgetState = {
    driveMode: false,
    guidance: null,
    destinationDetails: {
        [DestinationName.Home]: null,
        [DestinationName.Work]: null,
    },
    devUIComparison: false,
};

export const widgetReducer = (
    state: WidgetState = initialState,
    action: WidgetAction,
): WidgetState => {
    switch (action.type) {
        case WidgetActionType.SetDestinationDetails: {
            const { name, details } = action.payload;
            return {
                ...state,
                destinationDetails: {
                    ...state.destinationDetails,
                    [name]: details,
                },
            };
        }
        case WidgetActionType.UpdateGuidance: {
            return {
                ...state,
                guidance: action.payload,
            };
        }
        case WidgetActionType.ToggleDriveMode: {
            return {
                ...state,
                driveMode: action.payload,
            };
        }
        case WidgetActionType.ToggleUIComparison: {
            return {
                ...state,
                devUIComparison: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default widgetReducer;
